import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService, DialogService } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { I18nService } from '../../../services/i18n.service';

import { MeetingService } from '../../../services/meeting.service';
import { IMeeting, IMeetingListOptions, defaultMeetingListOptions } from '../../../interfaces/meeting.interface';
import { MeetingAddComponent } from '../meeting-add/meeting-add.component';

import CalendarLocaleHe from '../../../../locale/calendar.locale.he';

import { UserService } from '../../../services/user.service';

/**
 * Meetings List component
 */
@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.css'],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class MeetingComponent implements OnInit {
  /**
   * Calendar localization
   */
  he: any = CalendarLocaleHe;

  /**
   * All meetings in table
   */
  meetings: Array<IMeeting> = [];

  /**
   * Current selected meeting
   */
  selectedMeeting: IMeeting;

  /**
   * Request options for load meeting list
   */
  meetingListOptions: IMeetingListOptions = defaultMeetingListOptions;

  /**
   * User name
   */
  user: boolean;

  /**
   * Loading data mode
   */
  loading = true;

  /**
   * System is busy
   */
  busy = true;

  /**
   * Protocol filter dropdown
   */
  protocolDropdown = [
    {
      label: 'הכל',
      value: ''
    },
    {
      label: 'כן',
      value: 'true'
    },
    {
      label: 'לא',
      value: 'false'
    }
  ];

  /**
   * Component constructor
   *
   * @param rest meeting service
   * @param route active route
   * @param router router
   * @param confirmationService confirm dialog
   * @param dialogService popups service
   * @param messageService message service
   * @param i18nService translation service
   */
  constructor(
    public rest: MeetingService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private i18nService: I18nService,
    public User: UserService
  ) {}

  /**
   * Init component
   */
  ngOnInit() {}

  /**
   * lazy load meetings
   *
   * @param event event params from table
   */
  loadData(event: any): void {
    let filterDate: Date = null;
    if (event.filters.date && event.filters.date.value instanceof Date) {
      filterDate = new Date(event.filters.date.value);
      filterDate.setMinutes(filterDate.getMinutes() - filterDate.getTimezoneOffset());
    }

    this.meetingListOptions = {
      ...this.meetingListOptions,
      pageNumber: event.first / event.rows + 1,
      pageSize: event.rows,
      filterDate: filterDate ? filterDate.toISOString().slice(0, 10) : '',
      filterProtocols: event.filters.protocols ? event.filters.protocols.value : '',
      filterOrders: event.filters.orders ? event.filters.orders.value : '',
      sortField: event.sortField,
      sortOrder: event.sortOrder
    };
    this.getMeetings();
  }

  /**
   * Load Meetings List
   */
  getMeetings(): void {
    this.loading = true;
    this.busy = true;
    this.rest.getMeetings(this.meetingListOptions).subscribe(data => {
      this.meetings = data.data;
      this.meetingListOptions = {
        ...this.meetingListOptions,
        ...data.meta
      };
      this.loading = false;
      this.busy = false;
    });
  }

  /**
   * Add meeting
   */
  add(): void {
    if (!this.User.isEditor) {
      return;
    }

    const ref = this.dialogService.open(MeetingAddComponent, {
      header: this.i18nService.get('addMeeting'),
      width: '420px'
    });

    /* when popup closed, return filled meeting */
    ref.onClose.subscribe((meetingData: IMeeting) => {
      if (meetingData) {
        this.rest.addMeeting(meetingData).subscribe(
          result => {
            if (result.errors) {
              /*this.messageService.addAll(
              result.errors.map((i) => ({
                severity: 'error',
                summary: this.i18nService.get('addMeeting'),
                detail: i,
                life: 10000,
              })));*/
            } else {
              this.router.navigate([`/meeting-view/${result.id}/participants`]);
            }
          },
          err => {
            /*this.messageService.addAll(
            [{
              severity: 'error',
              summary: this.i18nService.get('addMeeting'),
              detail: err
            }]);*/
          }
        );
      }
    });
  }

  /**
   * View meeting
   *
   * @param meeting current meeting
   */
  view(meeting: IMeeting): void {
    this.router.navigate([`/meeting-view/${meeting.id}/participants`]);
  }

  /**
   * Remove meeting
   *
   * @param meeting Meeting
   */
  delete(meeting: IMeeting): void {
    if (!this.User.isEditor) {
      return;
    }

    this.confirmationService.confirm({
      message: this.i18nService.get('confirmDeleteMeetingMessage'), // 'Are you sure that you want to perform this action?',
      accept: () => {
        this.rest.deleteMeeting(meeting).subscribe(
          result => {
            if (result.errors) {
              /*this.messageService.addAll(
                result.errors.map((i) => ({
                  severity: 'error',
                  summary: this.i18nService.get('deleteMeeting'),
                  detail: i
                })));*/
            } else {
              const index = this.meetings.findIndex(el => meeting.id === el.id);
              this.meetings.splice(index, 1);
            }
          },
          err => {
            /*this.messageService.addAll(
              [{
                severity: 'error',
                summary: this.i18nService.get('deleteMeeting'),
                detail: err
              }]);*/
          }
        );
      }
    });
  }
}
