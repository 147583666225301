import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { OfferStatuses } from '../../../interfaces/offer.interface';

import CalendarLocaleHe from '../../../../locale/calendar.locale.he';

/**
 * Offer edit component
 */
@Component({
  selector: 'app-offer-edit',
  templateUrl: './offer-edit.component.html',
  styleUrls: ['./offer-edit.component.css']
})
export class OfferEditComponent implements OnInit {
  /**
   * Calendar localization
   */
  he: any = CalendarLocaleHe;

  /**
   * Offer form data
   */
  @Input() offerData = {
    id: 0,
    fullName: '',
    description: '',
    address: '',
    phone: '',
    status: 0,
    type: '',
    date: null,
    addressRoadSign: '',
    notesRoadSign: '',
    file: null
  };

  /**
   * Form validation status
   */
  validation = false;

  /**
   * Offer Statuses
   */
  offerStatuses = OfferStatuses;

  /**
   * Init component
   *
   * @param rest order service
   * @param route route
   * @param router router
   */
  constructor(private route: ActivatedRoute, private router: Router, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  /**
   * Init component
   */
  ngOnInit() {
    this.offerData = Object.assign({}, this.config.data.offer, {
      date: this.config.data.offer.date ? new Date(this.config.data.offer.date) : null,
      file: null
    });
  }

  /**
   * File uploader
   *
   * @param event event
   */
  fileUploader(event: any) {
    this.offerData.file = event.files[0];
  }

  /**
   * Check description for validation
   */
  changeDescription() {
    if (this.offerData.description) {
      this.validation = true;
    } else {
      this.validation = false;
    }
  }

  /**
   * Update order, check and return  offer object
   */
  updateOffer() {
    if (this.offerData.description && this.offerData.fullName) {
      if (this.offerData.date != null) {
        this.offerData.date.setMinutes(this.offerData.date.getMinutes() - this.offerData.date.getTimezoneOffset());
      }

      this.ref.close(this.offerData);
    }
  }
}
