import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService, DialogService, MessageService } from 'primeng/api';

import { IMeeting } from '../../../interfaces/meeting.interface';
import { MeetingService } from '../../../services/meeting.service';

import { ITopic, TopicStatuses } from '../../../interfaces/topic.interface';
import { TopicService } from '../../../services/topic.service';
import { TopicAddComponent } from '../../topic/topic-add/topic-add.component';
import { TopicCloneComponent } from '../../topic/topic-clone/topic-clone.component';

import { OfferLinkComponent } from '../../offer/offer-link/offer-link.component';
import { IOffer } from 'src/app/interfaces/offer.interface';
import { OfferService } from '../../../services/offer.service';

import { IPlan } from 'src/app/interfaces/plan.interface';
import { PlanService } from '../../../services/plan.service';

import { IOrder } from 'src/app/interfaces/order.interface';
import { OrderService } from 'src/app/services/order.service';

import { I18nService } from '../../../services/i18n.service';

import { UserService } from '../../../services/user.service';
import { PlanLinkComponent } from '../../plan/plan-link/plan-link.component';
import { MenuService } from 'src/app/services/menu.service';
import { OrderAddComponent } from '../../order/order-add/order-add.component';

/**
 * Topics of current Meeting
 */
@Component({
  selector: 'app-meeting-view-topics',
  templateUrl: './meeting-view-topics.component.html',
  styleUrls: ['./meeting-view-topics.component.css'],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class MeetingViewTopicsComponent implements OnInit {
  /**
   * Current meeting data
   */
  meetingData: IMeeting; // current Meeting

  /**
   * List of Topics
   */
  topics: ITopic[] = [];

  /**
   * Selected topic
   */
  selectedTopic: ITopic;

  /**
   * Selected Index
   */
  selectedIndex = -1;

  /**
   * Topic statuses
   */
  topicStatuses = TopicStatuses;

  /**
   * System busy - topics
   */
  busyTopics = true;

  /**
   * System busy - Offers
   */
  busyOffers = false;

  /**
   * System busy - Orders
   */
  busyOrder = false;

  /**
   * Input form
   */
  @Input() topicData = {
    id: 0,
    meetingId: 0,
    number: '',
    name: '',
    status: 0,
    offerId: 0,
    planId: 0,
    resolution: '',
    resolutionNumber: ''
  };

  /**
   * Offer of selected topic
   */
  offer: IOffer;

  /**
   * Plan of selected topic
   */
  plan: IPlan;

  /**
   * Order of selected topic
   */
  order: IOrder;

  /**
   * List of work instruction for selected topic
   */
  orders: IOrder[] = null;

  /**
   * Editor mode
   */
  editMode = false;

  /**
   * Offer fieldset
   */
  offerFieldset = this.i18nService.get('offer');

  /**
   * Topic tooltip
   */
  topicOrderStatus = this.i18nService.get('topicSetOrder');

  /**
   * Init Component
   *
   * @param rest Meeting service
   * @param restTopic Topic service
   * @param restOffer Offer
   * @param restOrder Order
   * @param route active route
   * @param router routing
   * @param confirmationService Confirm
   * @param dialogService dialog
   * @param messageService messages
   * @param i18nService translation
   */
  constructor(
    public rest: MeetingService,
    public restTopic: TopicService,
    public restOffer: OfferService,
    public restPlan: PlanService,
    public restOrder: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private i18nService: I18nService,
    public User: UserService,
    public menu: MenuService
  ) {
    this.menu.activeItem = null;
  }

  /**
   * Load current meeting
   */
  ngOnInit() {
    this.menu.activeItem = this.menu.items[0];
    this.getMeeting();
  }

  /**
   * Navigation, back to meeting list
   */
  back() {
    this.router.navigate(['/meetings']);
  }

  /**
   * Navigation, go to participants list of current meeting
   */
  toMeetingParticipants() {
    this.router.navigate([`/meeting-view/${this.meetingData.id}/participants`]);
  }

  /**
   * Navigation, go to topics list of current meeting
   */
  toMeetingTopics() {
    this.router.navigate([`/meeting-view/${this.meetingData.id}/topics`]);
  }

  /**
   * Navigation, go to Protocols list of current meeting
   */
  toMeetingProtocols() {
    this.router.navigate([`/meeting-view/${this.meetingData.id}/protocols`]);
  }

  /**
   * Get current meeting
   */
  getMeeting() {
    this.rest.getMeeting(this.route.snapshot.params.id).subscribe((data: IMeeting) => {
      this.meetingData = data;
      this.getTopics();
    });
  }

  /**
   * Get list of topics for current meeting
   */
  getTopics() {
    this.rest.getTopicsByMeeting(this.route.snapshot.params.id).subscribe((data: ITopic[]) => {
      this.topics = data;

      // check if we get topicId from URL

      if (this.route.snapshot.params.topicId) {
        const topicId = this.topics.findIndex(t => t.id.toString() === this.route.snapshot.params.topicId);
        if (topicId >= 0) {
          this.selectedIndex = topicId;
          this.selectionChangeOpen({
            index: topicId
          });
        }
      }

      if (this.selectedIndex > -1) {
        this.selectionChangeOpen({
          index: this.selectedIndex
        });
      }

      this.busyTopics = false;
    });
  }

  /**
   * Get topic status by id
   */
  getTopicStatus(statusId: number) {
    if (this.topicStatuses.find(t => t.value === statusId)) {
      return this.topicStatuses.find(t => t.value === statusId).label;
    } else {
      return 'Error';
    }
  }

  /**
   * Update offer and order
   */
  selectionChangeOpen(event) {
    if (this.editMode) {
      // event.stopPropagation();
      // return false;
    }
    // console.log(this.topics[event.index]);

    this.busyOffers = true;

    this.selectedTopic = this.topics[event.index];
    this.selectedIndex = event.index;

    console.log(this.topics[event.index]);

    /* Reload offer */
    this.offer = null;
    this.plan = null;
    if (this.selectedTopic && this.selectedTopic.offerId > 0) {
      this.restOffer.getOffer(this.selectedTopic.offerId).subscribe(data => {
        this.offer = data;
        this.busyOffers = false;
        if (this.offer) {
          this.offerFieldset = this.i18nService.get('topicAddOffer');
        }
      });
    } else if (this.selectedTopic && this.selectedTopic.planId > 0) {
      this.restPlan.getPlan(this.selectedTopic.planId).subscribe(data => {
        this.plan = data;
        this.busyOffers = false;
        if (this.plan) {
          this.offerFieldset = this.i18nService.get('topicAddPlan');
        }
      });
    } else {
      this.offer = null;
      this.busyOffers = false;
      this.offerFieldset = this.i18nService.get('offer');
    }

    /* Reload Orders */
    this.busyOrder = true;
    this.selectedTopic = this.topics[event.index];
    this.restTopic.getOrdersByTopicId(this.topics[event.index].id).subscribe(data => {
      this.orders = data;
      this.busyOrder = false;
    });

    /* Reload order */
    /*if (this.selectedTopic.orderId > 0) {
      this.restOrder.getOrder(this.selectedTopic.orderId).subscribe(data => {
        this.order = data;
      });
    }*/

    if (this.selectedTopic) {
      /* Fill form data */
      this.topicData = {
        id: this.selectedTopic.id,
        meetingId: this.meetingData.id,
        number: this.selectedTopic.number,
        name: this.selectedTopic.name,
        status: this.selectedTopic.status,
        offerId: this.selectedTopic.offerId,
        planId: this.selectedTopic.planId,
        resolution: this.selectedTopic.resolution,
        resolutionNumber: this.selectedTopic.resolutionNumber
      };
    }
  }

  /**
   * Fire when close accordion
   */
  selectionChangeClose() {
    if (!this.editMode) {
      this.selectedTopic = null;
      this.selectedIndex = -1;
      this.offerFieldset = this.i18nService.get('offer');
    }
  }

  /**
   * Add new topic to current Meeting in popup
   */
  addTopic() {
    if (!this.User.isEditor) {
      return;
    }

    /* Open popup with form */
    const ref = this.dialogService.open(TopicAddComponent, {
      data: {
        meetingId: this.meetingData.id
      },
      header: this.i18nService.get('addTopicHeader'), // 'יצירת נושא חדש',
      width: '580px',
      rtl: true
    });

    /* when popup closed, return filled topic */
    ref.onClose.subscribe((topic: ITopic) => {
      if (topic) {
        this.busyTopics = true;
        this.restTopic.addTopic(topic).subscribe(
          result => {
            if (result.errors) {
              this.messageService.addAll(
                result.errors.map(i => ({
                  severity: 'warn',
                  summary: this.i18nService.get('addTopicHeader'),
                  detail: i
                }))
              );
            } else {
              this.getMeeting();
            }
          },
          err => {
            this.messageService.addAll([
              {
                severity: 'warn',
                summary: this.i18nService.get('addTopicHeader'),
                detail: err
              }
            ]);
          }
        );
      }
    });
  }

  /**
   * Clone meeting with gray status
   */
  cloneTopic() {
    if (!this.User.isEditor) {
      return;
    }

    /* Open popup with form */
    const ref = this.dialogService.open(TopicCloneComponent, {
      data: {
        meetingId: this.meetingData.id
      },
      header: this.i18nService.get('cloneTopicHeader'), // 'Clone Topic',
      width: '50%',
      rtl: true
    });

    /* when popup closed, return filled topic */
    ref.onClose.subscribe((topic: ITopic) => {
      if (topic) {
        this.busyTopics = true;
        this.restTopic.addTopic(topic).subscribe(
          result => {
            if (result.errors) {
              this.messageService.addAll(
                result.errors.map(i => ({
                  severity: 'warn',
                  summary: this.i18nService.get('cloneTopicHeader'),
                  detail: i
                }))
              );
            } else {
              /* all fine, nothing to do */
              // this.meetingData.topics.push(result.id);
              // this.updateMeeting();
              this.getMeeting();
            }
          },
          err => {
            this.messageService.addAll([
              {
                severity: 'warn',
                summary: this.i18nService.get('cloneTopicHeader'),
                detail: err
              }
            ]);
          }
        );
      }
    });
  }

  /**
   * Update topic
   */
  updateTopic() {
    if (!this.User.isEditor) {
      return;
    }

    this.busyTopics = true;

    this.selectedTopic = {
      ...this.selectedTopic,
      ...this.topicData
    };

    this.restTopic.updateTopic(this.selectedTopic).subscribe(
      result => {
        if (result.errors) {
          this.messageService.addAll(
            result.errors.map(i => ({
              severity: 'warn',
              summary: this.i18nService.get('editTopic'),
              detail: i
            }))
          );
        } else {
          // this.meetingData.topics.push(result.id);
          // this.updateMeeting();
          // this.getMeeting();
          // this.topics[this.selectedIndex] = this.selectedTopic;

          this.busyTopics = false;
          // console.log(this.selectedTopic, this.topics);
          setTimeout(() => {
            this.getTopics();
          }, 4);
        }
        this.updateMeeting();
      },
      err => {
        this.messageService.addAll([
          {
            severity: 'warn',
            summary: this.i18nService.get('editTopic'),
            detail: err
          }
        ]);
      }
    );
    // this.getTopics();
    // this.selectedTopic = null;
  }

  /**
   * Delete meeting
   */
  deleteTopic() {
    if (!this.User.isEditor) {
      return;
    }

    if (!this.selectedTopic) {
      return;
    }

    const topicId = this.selectedTopic.id;
    this.confirmationService.confirm({
      message: this.i18nService.get('confirmDeleteTopicMessage'), // 'Are you sure that you want to perform this action?',
      accept: () => {
        this.busyTopics = true;
        this.restTopic.deleteTopic(topicId).subscribe(
          res => {
            this.selectedTopic = null;
            this.selectedIndex = null;
            this.offerFieldset = this.i18nService.get('offer');
            this.getMeeting();
          },
          err => {
            console.log(err);
          }
        );
      }
    });
  }

  /**
   * Link offter to topic
   */
  linkOffer() {
    if (!this.User.isEditor) {
      return;
    }

    /* Open popup with offer form */
    const ref = this.dialogService.open(OfferLinkComponent, {
      header: this.i18nService.get('linkOffer'),
      width: '50%',
      rtl: true
    });

    /* when popup closed, return filled topic */
    ref.onClose.subscribe((offer: IOffer) => {
      if (offer) {
        this.selectedTopic.offerId = offer.id;
        this.selectedTopic.planId = 0;
        this.topicData.offerId = offer.id;
        this.topicData.planId = 0;

        this.busyOffers = true;

        this.offerFieldset = this.i18nService.get('topicAddOffer');

        this.restTopic.updateTopic(this.selectedTopic).subscribe(
          result => {
            if (result.errors) {
              this.messageService.addAll(
                result.errors.map(i => ({
                  severity: 'warn',
                  summary: this.i18nService.get('linkOffer'),
                  detail: i
                }))
              );
            } else {
              /* all fine, nothing to do */
              // this.meetingData.topics.push(result.id);
              // this.updateMeeting();
              // this.getTopics();
              this.offer = offer;
              this.busyOffers = false;
            }
          },
          err => {
            this.messageService.addAll([
              {
                severity: 'warn',
                summary: this.i18nService.get('linkOffer'),
                detail: err
              }
            ]);
          }
        );
      }
    });
  }

  /**
   * Link plan to topic
   */
  linkPlan() {
    if (!this.User.isEditor) {
      return;
    }

    /* Open popup with plan form */
    const ref = this.dialogService.open(PlanLinkComponent, {
      header: this.i18nService.get('linkPlanHeader'),
      width: '50%',
      rtl: true
    });

    /* when popup closed, return filled topic */
    ref.onClose.subscribe((plan: IPlan) => {
      if (plan) {
        this.selectedTopic.planId = plan.id;
        this.selectedTopic.offerId = 0;
        this.topicData.offerId = 0;
        this.topicData.planId = plan.id;

        this.busyOffers = true;

        this.offerFieldset = this.i18nService.get('topicAddPlan');

        this.restTopic.updateTopic(this.selectedTopic).subscribe(
          result => {
            if (result.errors) {
              this.messageService.addAll(
                result.errors.map(i => ({
                  severity: 'warn',
                  summary: this.i18nService.get('linkOffer'),
                  detail: i
                }))
              );
            } else {
              /* all fine, nothing to do */
              // this.meetingData.topics.push(result.id);
              // this.updateMeeting();
              // this.getTopics();
              this.plan = plan;
              this.busyOffers = false;
            }
          },
          err => {
            this.messageService.addAll([
              {
                severity: 'warn',
                summary: this.i18nService.get('linkOffer'),
                detail: err
              }
            ]);
          }
        );
      }
    });
  }

  /**
   * Unlink offer from topic
   */
  unlinkOfferPlan() {
    if (!this.User.isEditor) {
      return;
    }

    this.selectedTopic.offerId = 0;
    this.topicData.offerId = 0;
    this.selectedTopic.planId = 0;
    this.topicData.planId = 0;

    this.busyOffers = true;

    this.offerFieldset = this.i18nService.get('offer');

    this.restTopic.updateTopic(this.selectedTopic).subscribe(
      result => {
        if (result.errors) {
          this.messageService.addAll(
            result.errors.map(i => ({
              severity: 'warn',
              summary: this.i18nService.get('unlinkOffer'),
              detail: i
            }))
          );
        } else {
          /* all fine, update meeting date */
          // this.getTopics();
          this.offer = null;
          this.plan = null;
          this.busyOffers = false;
        }
      },
      err => {
        this.messageService.addAll([
          {
            severity: 'warn',
            summary: this.i18nService.get('unlinkOffer'),
            detail: err
          }
        ]);
      }
    );
  }

  /**
   * add order
   */
  addOrder() {
    if (!this.User.isEditor) {
      return;
    }

    /* Open popup with form */
    const ref = this.dialogService.open(OrderAddComponent, {
      width: '90%',
      rtl: true,
      header: this.i18nService.get('addOrderHeader') // 'הוסף הוראת עבודה'
    });

    /* when popup closed, return filled participant */
    ref.onClose.subscribe((order: IOrder) => {
      if (order && this.selectedTopic) {
        order.topicId = this.selectedTopic.id;
        this.restOrder.addOrder(order).subscribe(
          result => {
            if (result.errors) {
              /*this.messageService.addAll(
              result.errors.map((i) => ({
                severity: 'warn',
                summary: this.i18nService.get('addOrderHeader'),
                detail: i
              })));*/
            }
            /* Reload Orders */
            this.busyOrder = true;
            this.restTopic.getOrdersByTopicId(this.selectedTopic.id).subscribe(data => {
              this.orders = data;
              this.busyOrder = false;
            });
          },
          err => {
            /*this.messageService.addAll(
            [{
              severity: 'warn',
              summary: this.i18nService.get('addOrderHeader'),
              detail: err
            }]);*/
          }
        );
      }
    });
  }

  /**
   * Sorting - up topic
   *
   * @param i topic index in accordion
   */
  upTopic(i: number) {
    // check user rights
    if (!this.User.isEditor) {
      return;
    }

    this.topics = [
      //
      ...this.topics.slice(0, i - 1),
      this.topics[i],
      this.topics[i - 1],
      ...this.topics.slice(i + 1)
    ];
    setTimeout(() => {
      this.selectedIndex--;
    }, 4);
    console.log(i, this.selectedIndex, this.topics);
  }

  /**
   * Sorting - down topic
   *
   * @param i topic index in accordion
   */
  downTopic(i: number) {
    // check user rights
    if (!this.User.isEditor) {
      return;
    }

    this.topics = [
      //
      ...this.topics.slice(0, i),
      this.topics[i + 1],
      this.topics[i],
      ...this.topics.slice(i + 2)
    ];
    setTimeout(() => {
      this.selectedIndex++;
    }, 4);
    console.log(i, this.selectedIndex, this.topics);
  }

  /**
   * Update meeting with participants and topics
   */
  updateMeeting() {
    if (!this.User.isEditor) {
      return;
    }

    this.meetingData.topics = this.topics.map(t => t.id);

    this.rest.updateMeeting(this.meetingData).subscribe(
      result => {
        this.getMeeting();
      },
      err => {
        this.getMeeting();
      }
    );
  }

  /**
   * Clear resolution fields
   */
  clearResolution() {
    if (!this.User.isEditor) {
      return;
    }

    this.topicData.resolutionNumber = '';
    this.topicData.resolution = '';
  }

  /**
   * remove order
   *
   * @param order Order object
   */
  deleteOrder(order: IOrder) {
    if (!this.User.isEditor) {
      return;
    }

    this.confirmationService.confirm({
      // message: 'Are you sure that you want to perform this action?',
      accept: () => {
        this.busyOrder = true;

        this.restOrder.deleteOrder(order).subscribe(
          result => {
            if (result.errors) {
              /*this.messageService.addAll(
                result.errors.map((i) => ({
                  severity: 'warn',
                  summary: 'Delete order',
                  detail: i
                })));*/
            } else {
              const index = this.orders.findIndex(el => order.id === el.id);
              this.orders.splice(index, 1);
              /*this.messageService.add({
                severity: 'success',
                summary: 'Order deleted',
                detail: 'Order successfully deleted'
              });*/

              this.busyOrder = false;
            }
          },
          err => {
            /*this.messageService.addAll(
              [{
                severity: 'warn',
                summary: 'Delete order',
                detail: err
              }]);*/
          }
        );
      }
    });
  }
}
