import { StringifyOptions } from 'querystring';

/**
 * Plan interface
 */
export interface IPlan {
  id: number;
  title: string;
  description: string;
  status: number;
  date: Date;
  comment?: string;
  history: IPlanHistory[];
  file?: File;
  filename?: string;
}

/**
 * Plan history interface
 */
export interface IPlanHistory {
  id: number;
  planId: number;
  status: number;
  date: Date;
  comment: string;
}

/**
 * Order List Options
 */
export interface IPlanListOptions {
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  isFree: number;
  filterTitle: string;
  filterDescription: string;
  filterStatus: string;
  filterDate: string;
  filterComment: string;
  sortField: string;
  sortOrder: number;
  filterType: number;
}

/**
 * default list options
 */
export const defaultPlanListOptions: IPlanListOptions = {
  totalRecords: 0,
  pageNumber: 1,
  pageSize: 20,
  isFree: 0,
  filterTitle: '',
  filterDescription: '',
  filterStatus: '',
  filterDate: '',
  filterComment: '',
  sortField: 'title',
  sortOrder: 1,
  filterType: 0
};

/**
 * Plan statuses
 */
export const PlanStatuses = [
  {
    label: 'תכנית בתכנון', // white, new
    value: 2
  },
  {
    label: 'תכנית לא רלוונטית', // gray, next
    value: 4
  },
  {
    label: 'תכנית נדחתה', // red, rejected
    value: 3
  },
  {
    label: 'תכנית מאושרת', // green, accepted
    value: 1
  },
  {
    label: 'בטיפול', // yellow, linked
    value: 5
  }
];
