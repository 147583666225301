import { Component, OnInit, Input } from '@angular/core';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { ParticipantService } from '../../../services/participant.service';
import { Positions } from '../../../interfaces/position.interface';

/**
 *
 */
@Component({
  selector: 'app-participant-add',
  templateUrl: './participant-add.component.html',
  styleUrls: ['./participant-add.component.css']
})
export class ParticipantAddComponent implements OnInit {
  /**
   * Participant
   */
  @Input() participantData = {
    id: 0,
    fullName: '',
    address: '',
    positionId: null,
    phone: '',
    email: '',
    isDisciplinary: false
  };

  /**
   * List of positions
   */
  positions = [];

  /**
   * email validation
   */
  emailValidation = false;

  /**
   * Constructor
   *
   * @param rest Participant service
   * @param restPositions Positions service
   * @param ref Ref to dialog
   * @param config Configuration of Dialog
   */
  constructor(public rest: ParticipantService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  /**
   * Init component
   */
  ngOnInit() {
    this.positions = Positions;
  }

  /**
   * Add participant, return data from popup to main component
   */
  addParticipant() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const email = this.participantData.email;
    if (email.length > 0 && reg.test(email) == false) {
      this.emailValidation = true;
      return false;
    } else {
      this.emailValidation = false;
    }

    if (this.participantData.fullName && this.participantData.positionId) {
      this.ref.close(this.participantData);
    }
  }
}
