import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { ITopic, ITopicListOptions, defaultTopicListOptions } from '../../../interfaces/topic.interface';
import { TopicService } from '../../../services/topic.service';
import { TopicAddComponent } from '../topic-add/topic-add.component';
import { TopicViewComponent } from '../topic-view/topic-view.component';
import { UserService } from 'src/app/services/user.service';

/**
 *
 */
@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.css'],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class TopicComponent implements OnInit {

  topics: Array<ITopic> = [];
  selectedTopic: ITopic;

  loading = true;

  topicListOptions: ITopicListOptions = defaultTopicListOptions;

  /**
   * Constructor
   *
   * @param rest Topic service
   * @param route route
   * @param router router
   * @param confirmationService Confirmation
   * @param dialogService Dialog
   * @param messageService Messages
   */
  constructor(
    public rest: TopicService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public User: UserService,
    private messageService: MessageService) { }

  ngOnInit() {
  }

  /**
   * lazy load
   *
   * @param event Event
   */
  loadData(event: any) {
    this.topicListOptions = {
      ...this.topicListOptions,
      pageNumber: event.first / event.rows + 1,
      pageSize: event.rows,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    };
    this.getTopics();
  }

  /**
   * load all topics
   */
  getTopics() {
    this.loading = true;
    this.topics = [];
    this.rest.getTopics().subscribe((data) => {
      this.topics = data.data;
      this.loading = false;
    });
  }

  /**
   * add topic
   */
  add() {

    if (!this.User.isEditor) { return; }

    /* Open popup with form */
    const ref = this.dialogService.open(TopicAddComponent, {
      header: 'Add Topic',
      width: '90%',
      rtl: true
    });

    /* when popup closed, return filled topic */
    ref.onClose.subscribe((topic: ITopic) => {
      if (topic) {
        this.rest.addTopic(topic).subscribe(result => {
          if (result.errors) {
            /*this.messageService.addAll(
              result.errors.map((i) => ({
                severity: 'warn',
                summary: 'Add topic',
                detail: i
              })));*/
          } else {
            this.topics.push(topic);
          }
        }, err => {
          /*this.messageService.addAll(
            [{
              severity: 'warn',
              summary: 'Add topic',
              detail: err
            }]);*/
        });
      }
    });
  }

  /**
   * view topic
   */
  view() {

    if (!this.User.isEditor) { return; }

    if (this.selectedTopic) {

      /* open popup for edit topic */
      const ref = this.dialogService.open(TopicViewComponent, {
        data: {
          topic: this.selectedTopic
        },
        header: 'View topic',
        width: '90%',
        rtl: true
      });

      /* return updated topic, when clicked Save in popup */
      ref.onClose.subscribe((topic: ITopic) => {
        if (topic) {
          this.rest.updateTopic(topic).subscribe(result => {
            if (result.errors) {
              /*this.messageService.addAll(
                result.errors.map((i) => ({
                  severity: 'warn',
                  summary: 'Edit topic',
                  detail: i
                })));*/
            } else {
              const index = this.topics.findIndex((el) => (topic.id === el.id));
              this.topics[index] = topic;
            }
          }, err => {
            /*this.messageService.addAll(
              [{
                severity: 'warn',
                summary: 'Edit topic',
                detail: err
              }]);*/
          });
        }
      });
    }
  }

  /**
   * remove topic with confirm
   */
  delete(topic: ITopic) {

    if (!this.User.isEditor) { return; }

    this.confirmationService.confirm({
      message: 'Are you sure that you want to perform this action?',
      accept: () => {
        this.rest.deleteTopic(topic.id)
          .subscribe(res => {
            this.getTopics();
          }, err => {
            console.log(err);
          });
      }
    });
  }

}
