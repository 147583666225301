import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { I18nService } from '../../../services/i18n.service';

import { PlanService } from '../../../services/plan.service';
import { IPlan, IPlanListOptions, defaultPlanListOptions, PlanStatuses } from '../../../interfaces/plan.interface';
import { PlanAddComponent } from '../plan-add/plan-add.component';
import { PlanEditComponent } from '../plan-edit/plan-edit.component';

import { UserService } from '../../../services/user.service';

import { environment } from 'src/environments/environment';

import CalendarLocaleHe from '../../../../locale/calendar.locale.he';

/**
 *
 */
const endpoint = environment.endpoint + '/plan';

/**
 * HTTP options for file upload
 */
const httpOptionsUpload = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'multipart/form-data'
  })
};

/**
 * Plan component
 */
@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.css'],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class PlanComponent implements OnInit {
  /**
   * Calendar localization
   */
  he: any = CalendarLocaleHe;

  /**
   * List of plans
   */
  plans: Array<IPlan> = [];

  /**
   * Selected plan
   */
  selectedPlan: IPlan;

  /**
   * Loading data mode
   */
  loading = true;

  /**
   * Request options for offer list
   */
  planListOptions: IPlanListOptions = defaultPlanListOptions;

  /**
   * Plan Statuses
   */
  planStatuses = PlanStatuses;

  /**
   * Plan Statuses Dropdown
   */
  dropdownStatuses = [];

  /**
   *
   * @param rest Plan service
   * @param route route
   * @param router router
   * @param confirmationService confirm
   */
  constructor(
    public rest: PlanService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private i18nService: I18nService,
    public User: UserService,
    public http: HttpClient
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.dropdownStatuses = [
      {
        label: 'הכל',
        value: ''
      },
      ...PlanStatuses
    ];
  }

  /**
   * Lazy load
   */
  loadData(event) {
    let filterDate: Date = null;
    if (event.filters.date && event.filters.date.value instanceof Date) {
      filterDate = new Date(event.filters.date.value);
      filterDate.setMinutes(filterDate.getMinutes() - filterDate.getTimezoneOffset());
    }

    this.planListOptions = {
      ...this.planListOptions,
      pageNumber: event.first / event.rows + 1,
      pageSize: event.rows,
      filterTitle: event.filters.title ? event.filters.title.value : '',
      filterDescription: event.filters.description ? event.filters.description.value : '',
      filterStatus: event.filters.status ? event.filters.status.value : '',
      filterDate: filterDate ? filterDate.toISOString().slice(0, 10) : '',
      filterComment: event.filters.comment ? event.filters.comment.value : '',
      sortField: event.sortField,
      sortOrder: event.sortOrder
    };
    this.getPlans();
  }

  /**
   * Load all plans
   */
  getPlans() {
    this.loading = true;
    // this.plans = [];
    this.rest.getPlans(this.planListOptions).subscribe(data => {
      this.plans = data.data;
      this.planListOptions = {
        ...this.planListOptions,
        ...data.meta
      };
      this.loading = false;
    });
  }

  /**
   * Add plan
   */
  add() {
    if (!this.User.isEditor) {
      return;
    }

    /* Open popup with form */
    const ref = this.dialogService.open(PlanAddComponent, {
      header: this.i18nService.get('addPlanHeader'),
      width: '580px',
      rtl: true
    });

    /* when popup closed, return filled offer */
    ref.onClose.subscribe((plan: IPlan) => {
      // console.log(plan);

      if (plan) {
        this.loading = true;

        const formData = new FormData();

        formData.append('title', plan.title);
        formData.append('description', plan.description);
        formData.append('comment', '');
        formData.append('date', plan.date ? plan.date.toISOString().split('T')[0] : '');
        if (plan.file) {
          formData.append('file', plan.file, plan.file.name);
        }

        this.http
          .post(endpoint + '/add', formData, {
            reportProgress: true,
            observe: 'events'
          })
          .subscribe(
            event => {
              console.log(event);
              if (event.type === 3) {
                this.getPlans();
              }
            },
            error => {
              console.log('error:', error);
              this.messageService.addAll([
                {
                  severity: 'warn',
                  summary: this.i18nService.get('addPlanHeader'),
                  detail: error
                }
              ]);
            }
          );
      }
    });
  }

  /**
   * Edit plan
   */
  edit() {
    if (!this.User.isEditor) {
      return;
    }

    if (this.selectedPlan) {
      /* open popup for edit plan */
      const ref = this.dialogService.open(PlanEditComponent, {
        data: {
          plan: this.selectedPlan
        },
        header: this.i18nService.get('editPlanHeader'),
        width: '580px',
        rtl: true
      });

      /* return updated plan, when clicked Save in popup */
      ref.onClose.subscribe((plan: IPlan) => {
        if (plan) {
          this.loading = true;

          const formData = new FormData();

          formData.append('id', plan.id.toString());
          formData.append('title', plan.title);
          formData.append('description', plan.description);
          formData.append('status', plan.status.toString());
          formData.append('comment', plan.comment || '');
          formData.append('date', plan.date ? plan.date.toISOString().split('T')[0] : '');
          if (plan.file) {
            formData.append('file', plan.file, plan.file.name);
          }

          this.http
            .post(endpoint + '/update', formData, {
              reportProgress: true,
              observe: 'events'
            })
            .subscribe(
              event => {
                console.log(event);
                if (event.type === 3) {
                  this.getPlans();
                }
              },
              error => {
                console.log('error:', error);
                this.messageService.addAll([
                  {
                    severity: 'warn',
                    summary: this.i18nService.get('addPlanHeader'),
                    detail: error
                  }
                ]);
              }
            );
        }
      });
    }
  }

  /**
   * View plan
   */
  view(): void {
    this.router.navigate([`/plan-view/${this.selectedPlan.id}`]);
  }

  /**
   * remove plan
   *
   * @param plan Plan object
   */
  delete(plan: IPlan) {
    if (!this.User.isEditor) {
      return;
    }

    this.confirmationService.confirm({
      message: this.i18nService.get('confirmDeletePlanMessage'),
      accept: () => {
        this.loading = true;

        this.rest.deletePlan(plan).subscribe(
          result => {
            if (result.errors) {
              /*this.messageService.addAll(
                result.errors.map((i) => ({
                  severity: 'warn',
                  summary: this.i18nService.get('deletePlan'),
                  detail: i
                })));*/
            } else {
              this.getPlans();
            }
          },
          err => {
            /*this.messageService.addAll(
              [{
                severity: 'warn',
                summary: this.i18nService.get('deletePlan'),
                detail: err
              }]);*/
          }
        );
      }
    });
  }

  /**
   * Get plans status by id
   */
  getPlanStatus(statusId: number) {
    if (this.planStatuses.find(t => t.value === statusId)) {
      return this.planStatuses.find(t => t.value === statusId).label;
    } else {
      return 'Error';
    }
  }
}
