import { Component, OnInit, Input } from '@angular/core';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { IMeeting, IMeetingListOptions, defaultMeetingListOptions } from '../../../interfaces/meeting.interface';
import { MeetingService } from '../../../services/meeting.service';

import CalendarLocaleHe from '../../../../locale/calendar.locale.he';

/**
 * Component for create meeting
 */
@Component({
  selector: 'app-meeting-add',
  templateUrl: './meeting-add.component.html',
  styleUrls: ['./meeting-add.component.css'],
  providers: [MessageService]
})
export class MeetingAddComponent implements OnInit {

  /**
   * Calendar localization
   */
  he: any = CalendarLocaleHe;

  /**
   * Disabled dates
   */
  invalidDates: Array<Date>;

  /**
   * Default options for meeting list
   */
  meetingListOptions: IMeetingListOptions = {
    ...defaultMeetingListOptions,
    pageSize: 100
  };

  @Input() meetingData = {
    id: 0,
    date: null,
    protocol: '',
    participants: [],
    topics: []
  };

  constructor(
    public rest: MeetingService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService) { }

  ngOnInit() {
    this.getMeetingDates();
  }

  /**
   * Load Meetings Dates
   */
  getMeetingDates() {
    this.invalidDates = [];
    this.rest.getMeetings(this.meetingListOptions).subscribe((data) => {
      this.invalidDates = data.data.map(m => new Date(m.date));
    });
  }

  /**
   * Add meeting
   */
  addMeeting() {
    if (this.meetingData.date) {
      this.meetingData.date.setMinutes(this.meetingData.date.getMinutes() - this.meetingData.date.getTimezoneOffset());
      this.ref.close(this.meetingData);
    }
  }

  /**
   * Cancel input form
   */
  cancel() {
    this.ref.close();
  }
}
