import { Component, OnInit, Input } from '@angular/core';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

/**
 *
 */
@Component({
  selector: 'app-protocol-generate',
  templateUrl: './protocol-generate.component.html',
  styleUrls: ['./protocol-generate.component.css']
})
export class ProtocolGenerateComponent implements OnInit {

  /**
   *
   */
  @Input() protocolData = {
    comment: ''
  };

  /**
   *
   * @param rest Position Service
   */
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) { }

  /**
   *
   */
  ngOnInit() {
  }

  /**
   * add position
   */
  generateProtocol() {
    if (this.protocolData.comment) {
      this.ref.close(this.protocolData.comment);
    }
  }

  /**
   * Cancel input form
   */
  cancel() {
    this.ref.close();
  }
}
