import { Component, OnInit, Input } from '@angular/core';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { OrderService } from '../../../services/order.service';
import { IOrder } from '../../../interfaces/order.interface';

import { TopicService } from '../../../services/topic.service';
import { ITopic, ITopicListOptions, defaultTopicListOptions } from 'src/app/interfaces/topic.interface';

import CalendarLocaleHe from '../../../../locale/calendar.locale.he';

/**
 *
 */
@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.css']
})
export class OrderViewComponent implements OnInit {
  /**
   *
   */
  priorities = [];

  /**
   * locale for Calendar
   */
  he: any = CalendarLocaleHe;

  /**
   * Default topics options
   */
  topicListOptions: ITopicListOptions = defaultTopicListOptions;

  /**
   * topics for suggestions
   */
  topics: ITopic[] = [];

  /**
   * Selected topic from autocomplite
   */
  selectedTopic: ITopic;

  /**
   *
   */
  @Input() orderData = {
    id: 0,
    topicId: 0,
    priority: 4,
    description: '',
    address: '',
    expirationDate: new Date(),
    actualDate: null,
    status: false
  };

  /**
   * Init component
   * @param rest order service
   * @param route route
   * @param router router
   */
  constructor(public rest: OrderService, public restTopic: TopicService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.priorities = [
      { label: 'בחר עדיפות', value: null },
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 }
    ];
  }

  /**
   * init data
   */
  ngOnInit() {
    this.orderData = Object.assign({}, this.config.data.order, {
      actualDate: this.config.data.order.actualDate ? new Date(this.config.data.order.actualDate) : null,
      expirationDate: new Date(this.config.data.order.expirationDate)
    });

    if (this.orderData.topicId) {
      this.restTopic.getTopic(this.orderData.topicId).subscribe(
        data => {
          this.selectedTopic = data;
          console.log(this.selectedTopic);
        },
        error => {
          this.selectedTopic = null;
          this.orderData.topicId = null;
        }
      );
    }
  }

  /**
   * update order
   */
  updateOrder() {
    if (this.selectedTopic) {
      this.orderData.topicId = this.selectedTopic.id;
    }

    this.ref.close(this.orderData);
  }

  /**
   * close order
   */
  closeOrder() {
    this.ref.close(this.orderData);
  }

  /**
   * Get topics filtered by resolution number
   *
   * @param event event from autocomplete
   */
  getTopics(event) {
    this.topicListOptions.filterResolutionNumber = event.query;

    this.restTopic.getTopics(this.topicListOptions).subscribe((data: any) => {
      this.topics = data.data.filter((s: any) => s.resolutionNumber.toLocaleLowerCase().indexOf(event.query.toLocaleLowerCase()) === 0);
    });
  }
}
