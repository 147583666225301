/**
 * Topics of meeting
 */
export interface ITopic {
  id: number;
  meetingId: number;
  number: string;
  name: string;
  status: number;
  offerId: number;
  planId: number;
  resolution: string;
  resolutionNumber: string;
  orders: number[];
}

/**
 * Topics list query
 */
export interface ITopicListOptions {
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  filterNumber: string;
  filterName: string;
  filterStatus: string;
  filterResolutionNumber: string;
  sortField: string;
  sortOrder: number;
}

/**
 * default options
 */
export const defaultTopicListOptions: ITopicListOptions = {
  totalRecords: 0,
  pageNumber: 1,
  pageSize: 20,
  filterNumber: '',
  filterName: '',
  filterStatus: '',
  filterResolutionNumber: '',
  sortField: '',
  sortOrder: 1
};

/**
 * Topic statuses
 */
export const TopicStatuses = [
  {
    label: 'נושא לדיון', // white, new
    value: 2
  },
  {
    label: 'נושא לדיון מחדש', // gray, next
    value: 4
  },
  {
    label: 'נושא לא מאושר', // red, rejected
    value: 3
  },
  {
    label: 'נושא מאושר', // green, accepted
    value: 1
  }
];
