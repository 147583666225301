import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MenuItem } from 'primeng/api';

/**
 * Service for tab menu
 */
@Injectable({ providedIn: 'root' })
export class MenuService {
  /**
   * Menu items
   */
  items: MenuItem[];

  /**
   * Active item
   */
  _activeItem: MenuItem;

  /**
   * parent tabs:
   * 0 - comitette
   * 1 - inventory
   */
  item = 0;

  /**
   * Constructor
   *
   * @param route route
   * @param router router
   */
  constructor(private route: ActivatedRoute, private router: Router) {
    this.items = [
      {
        label: 'ישיבות ועדה',
        routerLink: ['/meetings']
      },
      {
        label: 'פניות תושבים',
        routerLink: ['/offers']
      },
      {
        label: 'תכניות תנועה', // Traffics plans
        routerLink: ['/plans']
      },
      {
        label: 'אלפון ועדה',
        routerLink: ['/participants']
      }
    ];
  }

  get activeItem() {
    return this._activeItem;
  }

  set activeItem(item: MenuItem) {
    this._activeItem = item;
  }

  /**
   * Set comitette tab
   */
  setComitette() {
    this.items = [
      {
        label: 'ישיבות ועדה',
        routerLink: ['/meetings']
      },
      {
        label: 'פניות תושבים',
        routerLink: ['/offers']
      },
      {
        label: 'תכניות תנועה', // Traffics plans
        routerLink: ['/plans']
      },
      {
        label: 'אלפון ועדה',
        routerLink: ['/participants']
      }
    ];

    this.router.navigate([`/meetings`]);
    this.item = 0;
    this._activeItem = this.items[0];
  }

  /**
   * set inventory tab
   */
  setInventory() {
    this.items = [
      {
        label: 'אינוונטר'
      },
      {
        label: 'הוראות עבודה',
        routerLink: ['/orders']
      }
    ];

    this.router.navigate([`/orders`]);
    this.item = 1;
    this._activeItem = this.items[0];
  }
}
