import { Component, OnInit, Input } from '@angular/core';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';

import CalendarLocaleHe from '../../../../locale/calendar.locale.he';

/**
 * Component for download order
 */
@Component({
  selector: 'app-order-download',
  templateUrl: './order-download.component.html',
  styleUrls: ['./order-download.component.css'],
  providers: [MessageService]
})
export class OrderDownloadComponent implements OnInit {
  /**
   * Calendar localization
   */
  he: any = CalendarLocaleHe;

  /**
   *
   */
  @Input() orderData = {
    id: 0,
    dateFrom: null,
    dateTo: null,
    status: ''
  };

  /**
   * Order Statuses
   */

  orderStatuses = [
    {
      label: 'הכל',
      value: ''
    },
    {
      label: 'לא בוצע',
      value: false
    },
    {
      label: 'בוצע',
      value: true
    }
  ];

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private messageService: MessageService) {}

  ngOnInit() {}

  /**
   * Select date and status
   */
  downloadOrders() {
    if (this.orderData.dateFrom) {
      this.orderData.dateFrom.setMinutes(this.orderData.dateFrom.getMinutes() - this.orderData.dateFrom.getTimezoneOffset());
    }
    if (this.orderData.dateTo) {
      this.orderData.dateTo.setMinutes(this.orderData.dateTo.getMinutes() - this.orderData.dateTo.getTimezoneOffset());
    }

    this.ref.close(this.orderData);
  }

  /**
   * Cancel input form
   */
  cancel() {
    this.ref.close();
  }
}
