/**
 * Hebrew locale for calendar component
 */
const CalendarLocaleHe = {
  firstDayOfWeek: 0,
  dayNames: ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת', 'ראשון'],
  dayNamesShort: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש', 'א'],
  dayNamesMin: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש', 'א'],
  monthNames: ['ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי', 'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'],
  monthNamesShort: ['ינו', 'פבר', 'מרץ', 'אפר', 'מאי', 'יונ', 'יול', 'אוג', 'ספט', 'אוק', 'נוב', 'דצמ'],
  today: 'היום',
  clear: 'נקה',
  // dateFormat: 'mm/dd/yy',
  weekHeader: 'Wk'
};

export default CalendarLocaleHe;
