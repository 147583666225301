import { Component, OnInit } from '@angular/core';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { ParticipantService } from '../../../services/participant.service';
import { IParticipant } from '../../../interfaces/participant.interface';
import { Positions } from '../../../interfaces/position.interface';

/**
 * View Participant
 */
@Component({
  selector: 'app-participant-view',
  templateUrl: './participant-view.component.html',
  styleUrls: ['./participant-view.component.css']
})
export class ParticipantViewComponent implements OnInit {
  /**
   * Current participant
   */
  participantData: IParticipant;

  /**
   * list of positions
   */
  positions = [];

  /**
   * email validation
   */
  emailValidation = false;

  /**
   * Constructor
   *
   * @param rest participants service
   * @param restPositions positions service
   * @param ref Reference to Dialog
   * @param config Configuration of Dialog
   */
  constructor(public rest: ParticipantService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  /**
   * Init component, clone participant data, request list of positions
   */
  ngOnInit() {
    this.participantData = Object.assign({}, this.config.data.participant);
    this.positions = Positions;
  }

  /**
   * Update participant
   */
  updateParticipant() {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const email = this.participantData.email;
    if (email.length > 0 && reg.test(email) == false) {
      this.emailValidation = true;
      return false;
    } else {
      this.emailValidation = false;
    }

    if (
      /* this.participantData.address &&
      this.participantData.email && */
      this.participantData.fullName &&
      /* this.participantData.phone && */
      (this.participantData.positionId > 0 && this.participantData.positionId <= Positions.length)
    ) {
      this.ref.close(this.participantData);
    }
  }
}
