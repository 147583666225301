/**
 * Orders
 */
export interface IOrder {
  id: number;
  topicId: number;
  planId: number;
  offerId: number;
  priority: number;
  description: string;
  address: string;
  expirationDate: Date;
  actualDate?: Date;
  action: string;
  status: boolean;
  dateFrom: Date;
  dateTo: Date;
}

/**
 * Order List Options
 */
export interface IOrderListOptions {
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  filterPriority: string;
  filterDescription: string;
  filterAddress: string;
  filterExpirationDate: string;
  filterActualDate: string;
  sortField: string;
  sortOrder: number;
}

/**
 * default list options
 */
export const defaultOrderListOptions: IOrderListOptions = {
  totalRecords: 0,
  pageNumber: 1,
  pageSize: 20,
  filterPriority: '',
  filterDescription: '',
  filterAddress: '',
  filterExpirationDate: '',
  filterActualDate: '',
  sortField: 'priority',
  sortOrder: 1
};

/**
 * Order statuses
 */
export const OrderStatuses = [
  {
    label: 'תכנית בתכנון', // white, new
    value: 2
  },
  {
    label: 'תכנית לא רלוונטית', // gray, next
    value: 4
  },
  {
    label: 'לא בוצע', // red, rejected
    value: 3
  },
  {
    label: 'בוצע', // green, accepted
    value: 1
  },
  {
    label: 'בטיפול', // yellow, linked
    value: 5
  }
];
