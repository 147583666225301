import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { IParticipant, IParticipantListOptions, defaultParticipantListOptions } from '../interfaces/participant.interface';

import { environment } from 'src/environments/environment';

/**
 * endpoints
 * GET /api/participant/list
 * GET /api/participant/get/1
 * POST /api/participant/add
 * POST /api/participant/update
 * POST /api/participant/delete
 */
const endpoint = environment.endpoint + '/participant';

/**
 * Http options
 */
const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  })
};

/**
 * Participants service
 */
@Injectable({
  providedIn: 'root'
})
export class ParticipantService {

  /**
   * Inject http client
   *
   * @param http Http Client
   */
  constructor(private http: HttpClient) { }

  /**
   * Extract server data
   *
   * @param res server response
   */
  private extractData(res: Response) {
    // console.log('Response:', res);
    const body = res;
    return body || {};
  }

  /**
   * Participants list
   *
   * @param query List options
   */
  getParticipants(query: IParticipantListOptions = defaultParticipantListOptions): Observable<any> {

    query = {
      ...defaultParticipantListOptions,
      ...query
    };

    const getRequest = `${endpoint}/list?pageNumber=${
      query.pageNumber ? query.pageNumber : 1
      }&pageSize=${
      query.pageSize ? query.pageSize : 10
      }&filterFullName=${
      query.filterFullName
      }&filterAddress=${
      query.filterAddress
      }&filterPositionId=${
      query.filterPositionId.toString() !== '0' ? query.filterPositionId : ''
      }&filterPhone=${
      query.filterPhone
      }&filterEmail=${
      query.filterEmail
      }&filterIsDisciplinary=${
      query.filterIsDisciplinary
      }&sortField=${
      query.sortField
      }&sortOrder=${
      query.sortOrder
      }`;

    return this.http.get(getRequest, httpOptions).pipe(
      map(this.extractData));
  }

  /**
   * Get participant by id
   *
   * @param id participant id
   */
  getParticipant(id: number): Observable<any> {
    return this.http.get(endpoint + '/get/' + id).pipe(
      map(this.extractData));
  }

  /**
   * Add new participant
   *
   * @param participant participant
   */
  addParticipant(participant: IParticipant): Observable<any> {
    // console.log('addParticipant:', participant);
    return this.http.post<any>(endpoint + '/add', JSON.stringify(participant), httpOptions).pipe(
      tap((p: IParticipant) => console.log(`added participant w/ id=${p.id}`)),
      catchError(this.handleError<any>('addParticipant'))
    );
  }

  /**
   * Update participant
   *
   * @param participant participant
   */
  updateParticipant(participant: IParticipant): Observable<any> {
    return this.http.post(endpoint + '/update', JSON.stringify(participant), httpOptions).pipe(
      tap((p: IParticipant) => console.log(`updated participant w/ id=${p.id}`)),
      catchError(this.handleError<any>('updateParticipant'))
    );
  }

  /**
   * Remove participant
   *
   * @param participant participant
   */
  deleteParticipant(participant: IParticipant): Observable<any> {
    return this.http.post<any>(endpoint + '/delete', JSON.stringify(participant), httpOptions).pipe(
      tap((p: IParticipant) => console.log(`deleted participant w/ id=${p.id}`)),
      catchError(this.handleError<any>('deleteParticipant'))
    );
  }

  /**
   * errors handler
   * @param operation operation
   * @param result result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
