export const Positions = [
  {
    label: 'ראש ועדה',
    value: 1
  },
  {
    label: 'מהנדס העיר',
    value: 2
  },
  {
    label: 'ראש העיר',
    value: 3
  },
  {
    label: 'רכזת ועדה',
    value: 4
  },
  {
    label: 'אדריכל העיר',
    value: 5
  },
  {
    label: 'נציג ציבור',
    value: 6
  }
];
