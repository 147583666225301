import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { IPlan, IPlanListOptions, defaultPlanListOptions } from '../interfaces/plan.interface';

import { environment } from 'src/environments/environment';

/**
 * endpoints
 * GET /api/plan/list
 * GET /api/plan/get/<offerId>
 * GET /api/plan/getTopics/<offerId>
 * POST /api/plan/add
 * POST /api/plan/update
 * POST /api/plan/delete
 */
const endpoint = environment.endpoint + '/plan';

/**
 * HTTP options
 */
const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  })
};

/**
 * HTTP options for file upload
 */
const httpOptionsUpload = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'multipart/form-data'
  })
};

/**
 * Plans service
 */
@Injectable({
  providedIn: 'root'
})
export class PlanService {

  /**
   * Inject http client
   *
   * @param http http client
   */
  constructor(private http: HttpClient) { }

  /**
   * Extract server data
   *
   * @param res server response
   */
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  /**
   * Plans list
   *
   * @param query Request parameters
   */
  getPlans(query: IPlanListOptions = defaultPlanListOptions): Observable<any> {

    query = {
      ...defaultPlanListOptions,
      ...query
    };

    const getRequest = `${endpoint}/list?pageNumber=${
      query.pageNumber ? query.pageNumber : 1
      }&pageSize=${
      query.pageSize ? query.pageSize : 10
      }&filterTitle=${
      query.filterTitle
      }&filterDescription=${
      query.filterDescription
      }&filterStatus=${
      query.filterStatus
      }&filterDate=${
      query.filterDate
      }&filterComment=${
      query.filterComment
      }&sortField=${
      query.sortField
      }&sortOrder=${
      query.sortOrder
      }`;

    return this.http.get(getRequest, httpOptions).pipe(
      map(this.extractData));
  }

  /**
   * Get plan by id
   *
   * @param id plan id
   */
  getPlan(id: number): Observable<any> {
    return this.http.get(endpoint + '/get/' + id).pipe(
      map(this.extractData));
  }

  /**
   * Add new plan
   *
   * @param plan plan object
   */
  addPlan(plan: IPlan): Observable<any> {

    const formData = new FormData();
    formData.append('title', plan.title);
    formData.append('description', plan.description);
    formData.append('comment', '');
    formData.append('file', plan.file, plan.file.name);

    // console.log(formData);

    return this.http.post<any>(endpoint + '/add', formData, httpOptionsUpload).pipe(
      tap((o: IPlan) => console.log(`added plan id=${o.id}`)),
      catchError(this.handleError<any>('addPlan'))
    );
  }

  /**
   * Update plan
   *
   * @param plan Plan object
   */
  updatePlan(plan: IPlan): Observable<any> {
    return this.http.post(endpoint + '/update', JSON.stringify(plan), httpOptions).pipe(
      tap((o: IPlan) => console.log(`updated plan id=${o.id}`)),
      catchError(this.handleError<any>('updatePlan'))
    );
  }

  /**
   * Remove plan
   *
   * @param plan Plan object
   */
  deletePlan(plan: IPlan): Observable<any> {
    return this.http.post<any>(endpoint + '/delete', JSON.stringify(plan), httpOptions).pipe(
      tap((o: IPlan) => console.log(`deleted plan id=${o.id}`)),
      catchError(this.handleError<any>('deletePlan'))
    );
  }

  /**
   * Get topics by plan id
   *
   * @param planId Plan id
   */
  getTopicsByPlan(planId: number): Observable<any> {
    return this.http.get(endpoint + '/getTopicsByPlanID/' + planId).pipe(
      map(this.extractData));
  }

  /**
   * error handler
   * @param operation operation
   * @param result result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
