/**
 * Offers from citizens
 */
export interface IOffer {
  id: number;
  fullName: string;
  description: string;
  address?: string;
  phone?: string;
  status: number;
  type?: string;
  date?: Date;
  addressRoadSign?: string;
  notesRoadSign?: string;
  file?: File;
  filename?: string;
}

/**
 * Offer list query
 */
export interface IOfferListOptions {
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  isFree: number; // not linked to Topic OR gray status
  filterId: string;
  filterFullName: string;
  filterDescription: string;
  filterAddress: string;
  filterPhone: string;
  filterStatus: string;
  filterDate: string;
  sortField: string;
  sortOrder: number;
  filterType: number;
}

/**
 * default options
 */
export const defaultOfferListOptions: IOfferListOptions = {
  totalRecords: 0,
  pageNumber: 1,
  pageSize: 20,
  isFree: 0,
  filterId: '',
  filterFullName: '',
  filterDescription: '',
  filterAddress: '',
  filterPhone: '',
  filterStatus: '',
  filterDate: '',
  sortField: 'title',
  sortOrder: 1,
  filterType: 0
};

/**
 * Offer statuses
 */
export const OfferStatuses = [
  {
    label: 'פנייה בתכנון', // white, new
    value: 2
  },
  {
    label: 'פנייה לא רלוונטית', // gray, next
    value: 4
  },
  {
    label: 'פנייה נדחתה', // red, rejected
    value: 3
  },
  {
    label: 'פנייה מאושרת', // green, accepted
    value: 1
  },
  {
    label: 'בטיפול', // yellow, linked
    value: 5
  }
];
