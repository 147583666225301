import { Component, OnInit } from '@angular/core';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { TopicService } from '../../../services/topic.service';
import { ITopic } from '../../../interfaces/topic.interface';

import { OfferService } from '../../../services/offer.service';
import { IOffer, IOfferListOptions } from '../../../interfaces/offer.interface';

/**
 * Topic view component
 */
@Component({
  selector: 'app-topic-view',
  templateUrl: './topic-view.component.html',
  styleUrls: ['./topic-view.component.css']
})
export class TopicViewComponent implements OnInit {
  /**
   * Topic
   */
  topicData: ITopic;

  /**
   * list of offers
   */
  offers: IOffer[];

  /**
   * Offer options for autocomplete
   */
  offerListOptions: IOfferListOptions;

  /**
   * List of statuses
   */
  statuses = [
    {
      label: 'Status 1',
      value: 1
    },
    {
      label: 'Status 2',
      value: 2
    },
    {
      label: 'Status 3',
      value: 3
    },
    {
      label: 'Status 4',
      value: 4
    }
  ];

  /**
   * Constructor
   *
   * @param rest Topic servise
   * @param restOffer Offer service
   * @param ref Dialog
   * @param config Config for Dialog
   */
  constructor(
    public rest: TopicService,
    public restOffer: OfferService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  /**
   * init data
   */
  ngOnInit() {
    this.topicData = this.config.data.topic;

    this.offerListOptions = {
      ...this.offerListOptions,
      pageNumber: 1,
      pageSize: 10,
      isFree: 1
    };
  }

  /**
   * load offers
   *
   * @param event filter event for autocomplete
   */
  getOffers(event: any) {
    this.offerListOptions.filterId = event.query;
    this.restOffer.getOffers(this.offerListOptions).subscribe((data: Array<IOffer>) => {
      this.offers = data;
    });
  }

  /**
   * update topic
   */
  updateTopic() {
    this.ref.close(this.topicData);
  }
}
