/* ANGULAR */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import localeHe from '@angular/common/locales/he';
import { HttpClientModule } from '@angular/common/http';

/* PRIMENG */
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from 'primeng/inputmask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FieldsetModule } from 'primeng/fieldset';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DialogModule } from 'primeng/dialog';
import { ListboxModule } from 'primeng/listbox';
import { OrderListModule } from 'primeng/orderlist';
import { FileUploadModule } from 'primeng/fileupload';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BlockUIModule } from 'primeng/blockui';

/* COMMON */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* MEETING */
import { MeetingComponent } from './components/meeting/meeting/meeting.component';
import { MeetingAddComponent } from './components/meeting/meeting-add/meeting-add.component';
import { MeetingViewParticipantsComponent } from './components/meeting/meeting-view-participants/meeting-view-participants.component';
import { MeetingViewTopicsComponent } from './components/meeting/meeting-view-topics/meeting-view-topics.component';
import { MeetingViewProtocolsComponent } from './components/meeting/meeting-view-protocols/meeting-view-protocols.component';

/* PARTICIPANT */
import { ParticipantComponent } from './components/participant/participant/participant.component';
import { ParticipantAddComponent } from './components/participant/participant-add/participant-add.component';
import { ParticipantViewComponent } from './components/participant/participant-view/participant-view.component';

/* TOPIC */
import { TopicComponent } from './components/topic/topic/topic.component';
import { TopicAddComponent } from './components/topic/topic-add/topic-add.component';
import { TopicViewComponent } from './components/topic/topic-view/topic-view.component';

/* ORDER */
import { OrderComponent } from './components/order/order/order.component';
import { OrderAddComponent } from './components/order/order-add/order-add.component';
import { OrderViewComponent } from './components/order/order-view/order-view.component';
import { OrderDownloadComponent } from './components/order/order-download/order-download.component';

/* OFFER */
import { OfferComponent } from './components/offer/offer/offer.component';
import { OfferAddComponent } from './components/offer/offer-add/offer-add.component';
import { OfferEditComponent } from './components/offer/offer-edit/offer-edit.component';
import { OfferViewComponent } from './components/offer/offer-view/offer-view.component';
import { OfferLinkComponent } from './components/offer/offer-link/offer-link.component';
import { TopicCloneComponent } from './components/topic/topic-clone/topic-clone.component';

/* PROTOCOLS */
import { ProtocolGenerateComponent } from './components/protocol/protocol-generate/protocol-generate.component';
import { I18nComponent } from './components/i18n/i18n.component';

/* GLOBAL SERVICES */
import { UserService } from './services/user.service';
import { PlanComponent } from './components/plan/plan/plan.component';
import { PlanAddComponent } from './components/plan/plan-add/plan-add.component';
import { PlanEditComponent } from './components/plan/plan-edit/plan-edit.component';
import { PlanLinkComponent } from './components/plan/plan-link/plan-link.component';
import { PlanViewComponent } from './components/plan/plan-view/plan-view.component';
import { MenuService } from './services/menu.service';

import { DeviceDetectorModule } from 'ngx-device-detector';

/**
 * register he locale
 */
registerLocaleData(localeHe, 'he');

@NgModule({
  declarations: [
    AppComponent,

    MeetingComponent,
    MeetingAddComponent,
    MeetingViewParticipantsComponent,
    MeetingViewTopicsComponent,
    MeetingViewProtocolsComponent,

    ParticipantComponent,
    ParticipantAddComponent,
    ParticipantViewComponent,

    OrderComponent,
    OrderAddComponent,
    OrderViewComponent,
    OrderDownloadComponent,

    TopicComponent,
    TopicAddComponent,
    TopicViewComponent,
    TopicCloneComponent,

    OfferComponent,
    OfferAddComponent,
    OfferViewComponent,
    OfferEditComponent,
    OfferLinkComponent,

    ProtocolGenerateComponent,

    PlanComponent,
    PlanAddComponent,
    PlanEditComponent,
    PlanViewComponent,
    PlanLinkComponent,

    I18nComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MenuModule,
    MenubarModule,
    TabMenuModule,
    TableModule,
    ButtonModule,
    PaginatorModule,
    AccordionModule,
    ConfirmDialogModule,
    InputTextModule,
    DropdownModule,
    CheckboxModule,
    InputTextareaModule,
    CalendarModule,
    InputMaskModule,
    AutoCompleteModule,
    FieldsetModule,
    CardModule,
    PanelModule,
    DynamicDialogModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    DialogModule,
    ListboxModule,
    OrderListModule,
    FileUploadModule,
    TooltipModule,
    OverlayPanelModule,
    ProgressSpinnerModule,
    BlockUIModule,
    DeviceDetectorModule.forRoot()
  ],
  entryComponents: [
    ParticipantAddComponent,
    ParticipantViewComponent,
    OfferLinkComponent,
    OfferEditComponent,
    TopicAddComponent,
    TopicCloneComponent,
    ProtocolGenerateComponent,
    PlanLinkComponent,
    OrderDownloadComponent
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'he' }, UserService, MenuService],
  bootstrap: [AppComponent]
})
export class AppModule {}
