import { Component, OnInit, Input } from '@angular/core';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { IPlan, IPlanListOptions, defaultPlanListOptions } from '../../../interfaces/plan.interface';
import { PlanService } from '../../../services/plan.service';

/**
 * Add plan form
 */
@Component({
  selector: 'app-plan-link',
  templateUrl: './plan-link.component.html',
  styleUrls: ['./plan-link.component.css']
})
export class PlanLinkComponent implements OnInit {
  /**
   * Input form for plan
   */
  @Input() planData = {
    id: 0, // plan id
    title: '' // plan title
  };

  /**
   * List of plans
   */
  plans: IPlan[] = [];

  /**
   * Current plan
   */
  plan: IPlan;

  /**
   * Options for load offer list
   */
  planListOptions: IPlanListOptions = defaultPlanListOptions;

  /**
   * Constructor
   *
   * @param rest Topic service
   * @param restOffer Offerss service
   * @param ref Reference to Dialog
   * @param config Dialog config
   */
  constructor(public rest: PlanService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  /**
   * Init component
   */
  ngOnInit() {
    this.planListOptions = {
      ...this.planListOptions,
      pageNumber: 1,
      pageSize: 10,
      isFree: 1,
      filterType: 1
    };
  }

  /**
   * Load plans
   *
   * @param event Event data from autocomplite
   */
  getPlans(event: any) {
    this.planListOptions.filterTitle = event.query;
    this.planListOptions.filterDescription = event.query;

    this.rest.getPlans(this.planListOptions).subscribe((data: any) => {
      this.plans = data.data;
    });
  }

  /**
   * Save plan and return
   */
  addPlan() {
    this.ref.close(this.plan);
  }
}
