import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { IOrder, IOrderListOptions, defaultOrderListOptions } from '../interfaces/order.interface';

import { environment } from 'src/environments/environment';

/**
 * endpoints
 * GET /api/order/list
 * GET /api/order/get/1
 * POST /api/order/add
 * POST /api/order/update
 * POST /api/order/delete
 */
const endpoint = environment.endpoint + '/order';

/**
 * HTTP options
 */
const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  })
};

/**
 * Orders service
 */
@Injectable({
  providedIn: 'root'
})
export class OrderService {
  /**
   * Inject http client
   *
   * @param http http client
   */
  constructor(private http: HttpClient) {}

  /**
   * Extract server data
   *
   * @param res server response
   */
  private extractData(res: Response) {
    console.log('Response:', res);
    const body = res;
    return body || {};
  }

  /**
   * Orders list
   *
   * @param query Query options
   */
  getOrders(query: IOrderListOptions = defaultOrderListOptions): Observable<any> {
    query = {
      ...defaultOrderListOptions,
      ...query
    };

    const getRequest = `${endpoint}/list?pageNumber=${query.pageNumber ? query.pageNumber : 1}&pageSize=${
      query.pageSize ? query.pageSize : 10
    }&filterPriority=${query.filterPriority}&filterDescription=${query.filterDescription}&filterAddress=${
      query.filterAddress
    }&filterExpirationDate=${query.filterExpirationDate}&filterActualDate=${query.filterActualDate}&sortField=${
      query.sortField
    }&sortOrder=${query.sortOrder}`;

    return this.http.get(getRequest, httpOptions).pipe(map(this.extractData));
  }

  /**
   * Get order by id
   *
   * @param id order id
   */
  getOrder(id: number): Observable<any> {
    return this.http.get(endpoint + '/get/' + id).pipe(map(this.extractData));
  }

  /**
   * Get all orders by plan id
   *
   * @param planId plan id
   */
  getOrdersByPlanID(planId: number): Observable<any> {
    return this.http.get(endpoint + '/getOrdersByPlanID/' + planId).pipe(map(this.extractData));
  }

  /**
   * Get all orders by offer id
   *
   * @param offerId offer id
   */
  getOrdersByOfferID(offerId: number): Observable<any> {
    return this.http.get(endpoint + '/getOrdersByOfferID/' + offerId).pipe(map(this.extractData));
  }

  /**
   * Add new order
   *
   * @param order order
   */
  addOrder(order: IOrder): Observable<any> {
    console.log('addOrder:', JSON.stringify(order));
    return this.http.post<any>(endpoint + '/add', JSON.stringify(order), httpOptions).pipe(
      tap((o: IOrder) => console.log(`added order id=${o.id}`)),
      catchError(this.handleError<any>('addOrder'))
    );
  }

  /**
   * Update order
   *
   * @param order Order object
   */
  updateOrder(order: IOrder): Observable<any> {
    return this.http.post(endpoint + '/update', JSON.stringify(order), httpOptions).pipe(
      tap((o: IOrder) => console.log(`updated order id=${o.id}`)),
      catchError(this.handleError<any>('updateOrder'))
    );
  }

  /**
   * Remove order
   *
   * @param order Order object
   */
  deleteOrder(order: IOrder): Observable<any> {
    return this.http.post<any>(endpoint + '/delete', JSON.stringify(order), httpOptions).pipe(
      tap((o: IOrder) => console.log(`deleted order id=${o.id}`)),
      catchError(this.handleError<any>('deleteOrder'))
    );
  }

  /**
   * Error handler
   *
   * @param operation operation
   * @param result result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
