import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService, DialogService, MessageService } from 'primeng/api';

import { IMeeting } from '../../../interfaces/meeting.interface';
import { MeetingService } from '../../../services/meeting.service';

import { ProtocolService } from 'src/app/services/protocol.service';
import { IProtocol } from 'src/app/interfaces/protocol.interface';
import { ProtocolGenerateComponent } from '../../protocol/protocol-generate/protocol-generate.component';

import { environment } from 'src/environments/environment';

import { I18nService } from '../../../services/i18n.service';

import { UserService } from '../../../services/user.service';
import { MenuService } from 'src/app/services/menu.service';
import { ITopic } from 'src/app/interfaces/topic.interface';

/**
 * Topics of current Meeting
 */
@Component({
  selector: 'app-meeting-view-protocols',
  templateUrl: './meeting-view-protocols.component.html',
  styleUrls: ['./meeting-view-protocols.component.css'],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class MeetingViewProtocolsComponent implements OnInit {
  /**
   * Current meeting data
   */
  meetingData: IMeeting; // current Meeting

  /**
   * List of topics
   */
  topics: ITopic[] = [];

  /**
   * List of Protocols
   */
  protocols: IProtocol[] = [];

  /**
   * Can genetare protocol?
   */
  canGenerate = false;

  /**
   * Index of selected protocol
   */
  selectedProtocolIndex = 0;

  /**
   * Upload endpoint for signed protocol
   */
  uploadEndpoint = environment.endpoint + '/meeting/addSigned';

  /**
   * System is busy - show progress spinner
   */
  busy = false;

  /**
   * Init Component
   *
   * @param rest Meeting service
   * @param restTopic Topic service
   * @param route active route
   * @param router routing
   * @param confirmationService confirm dialog
   * @param dialogService dialog
   * @param messageService messages
   * @param i18nService transtations
   */
  constructor(
    public rest: MeetingService,
    public restProtocol: ProtocolService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private i18nService: I18nService,
    public User: UserService,
    public menu: MenuService
  ) {
    this.menu.activeItem = null;
  }

  /**
   * Load current meeting
   */
  ngOnInit() {
    this.busy = true;
    this.menu.activeItem = this.menu.items[0];
    this.getMeeting();
  }

  /**
   * Navigation, back to meeting list
   */
  back() {
    this.router.navigate(['/meetings']);
  }

  /**
   * Navigation, go to participants list of current meeting
   */
  toMeetingParticipants() {
    this.router.navigate([`/meeting-view/${this.meetingData.id}/participants`]);
  }

  /**
   * Navigation, go to topics list of current meeting
   */
  toMeetingTopics() {
    this.router.navigate([`/meeting-view/${this.meetingData.id}/topics`]);
  }

  /**
   * Navigation, go to Protocols list of current meeting
   */
  toMeetingProtocols() {
    this.router.navigate([`/meeting-view/${this.meetingData.id}/protocols`]);
  }

  /**
   * Get current meeting
   */
  getMeeting() {
    this.rest.getMeeting(this.route.snapshot.params.id).subscribe((data: IMeeting) => {
      this.meetingData = data;
      this.getProtocols();
      this.getTopics();
    });
  }

  /**
   * Get list of topics for current meeting
   */
  getProtocols() {
    this.rest.getProtocolsByMeeting(this.route.snapshot.params.id).subscribe(data => {
      this.protocols = data;
      this.busy = false;
    });
  }

  getTopics() {
    this.rest.getTopicsByMeeting(this.route.snapshot.params.id).subscribe(data => {
      this.topics = data;
      this.checkCanGenerate();
    });
  }

  /**
   * check all topics for white status
   */
  checkCanGenerate() {
    this.canGenerate = this.topics.every(item => item.status !== 2) && this.topics.length > 0;
  }

  /**
   * Generate protocol with comment
   */
  generate() {
    if (!this.User.isEditor) {
      return;
    }

    /* Open popup with form */
    const ref = this.dialogService.open(ProtocolGenerateComponent, {
      width: '420px',
      rtl: true,
      header: this.i18nService.get('protocolGenerateHeader') // 'הפקת קובץ פרוטוקול חדש',
    });

    /* when popup closed, return filled participant */
    ref.onClose.subscribe((comment: string) => {
      if (comment) {
        this.busy = true;
        this.rest.protocolGenerate(this.meetingData.id, comment).subscribe(
          result => {
            if (result.errors) {
              this.messageService.addAll(
                result.errors.map(i => ({
                  severity: 'warn',
                  summary: this.i18nService.get('generateProtocol'), // 'Generate protocol',
                  detail: i
                }))
              );
            } else {
              // this.messageService.add({
              //   severity: 'success',
              //   summary: this.i18nService.get('generateProtocol'), // 'Generate protocol',
              //   detail: this.i18nService.get('protocolGeneratedSuccessfully')
              // });
            }
            this.getProtocols();
          },
          err => {
            this.messageService.addAll([
              {
                severity: 'warn',
                summary: this.i18nService.get('generateProtocol'), // 'Generate protocol',
                detail: err
              }
            ]);
            this.getProtocols();
          }
        );
      }
    });
  }

  /**
   * Download protocol
   */
  download(protocolUrl: string) {
    this.router.navigate([protocolUrl]);
  }

  /**
   * Delete meeting
   */
  delete(protocolId: number) {
    if (!this.User.isEditor) {
      return;
    }

    this.confirmationService.confirm({
      message: this.i18nService.get('confirmDeleteProtocolMessage'), // 'Are you sure that you want to perform this action?',
      accept: () => {
        this.busy = true;
        this.rest.protocolDelete(protocolId).subscribe(
          res => {
            this.getProtocols();
          },
          err => {
            console.log(err);
          }
        );
      }
    });
  }

  /**
   * Update meeting with participants and topics
   */
  updateMeeting() {
    if (!this.User.isEditor) {
      return;
    }

    this.rest.updateMeeting(this.meetingData).subscribe(
      result => {
        console.log(result);
      },
      err => {
        console.log(err);
      }
    );
  }
}
