import { Component, OnInit, Input } from '@angular/core';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { ITopic, TopicStatuses } from '../../../interfaces/topic.interface';
import { TopicService } from '../../../services/topic.service';
import { IOffer, IOfferListOptions } from '../../../interfaces/offer.interface';
import { IPlan, IPlanListOptions } from '../../../interfaces/plan.interface';
import { OfferService } from '../../../services/offer.service';
import { PlanService } from '../../../services/plan.service';

/**
 * Add topic form
 */
@Component({
  selector: 'app-topic-add',
  templateUrl: './topic-add.component.html',
  styleUrls: ['./topic-add.component.css']
})
export class TopicAddComponent implements OnInit {
  /**
   * Input form for topic
   */
  @Input() topicData = {
    id: 0,
    meetingId: 0,
    number: '',
    name: '',
    offer: null,
    offerId: 0,
    plan: null,
    planId: 0,
    status: 0
  };

  /**
   * List of offers
   */
  offers: IOffer[];

  /**
   * List of plans
   */
  plans: IOffer[];

  /**
   * Current offer
   */
  offer: IOffer;

  /**
   * List of statuses
   */
  statuses = TopicStatuses;

  /**
   * Options for load offer list
   */
  offerListOptions: IOfferListOptions;

  /**
   * Options for load plan list
   */
  planListOptions: IPlanListOptions;

  /**
   * Offer validation
   */
  offerValid = false;

  /**
   * Plan validation
   */
  planValid = false;

  /**
   *
   * @param rest Topic service
   * @param restOffer Offerss service
   * @param ref Reference to Dialog
   * @param config Dialog config
   */
  constructor(
    public rest: TopicService,
    public restOffer: OfferService,
    public restPlan: PlanService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.offerListOptions = {
      ...this.offerListOptions,
      pageNumber: 1,
      pageSize: 10,
      isFree: 1,
      filterType: 1
    };

    this.planListOptions = {
      ...this.planListOptions,
      pageNumber: 1,
      pageSize: 10,
      isFree: 1,
      filterType: 1
    };
  }

  /**
   * select offer
   *
   */
  offerSelect() {
    if (this.topicData.offer && !this.topicData.number) {
      this.topicData.number = this.topicData.offer.id;
    }

    if (this.topicData.offer && !this.topicData.name) {
      this.topicData.name = this.topicData.offer.description;
    }
  }

  /**
   * select plan
   *
   */
  planSelect() {
    if (this.topicData.plan && !this.topicData.number) {
      this.topicData.number = this.topicData.plan.title;
    }

    if (this.topicData.plan && !this.topicData.name) {
      this.topicData.name = this.topicData.plan.description;
    }
  }

  /**
   * Load offers
   *
   * @param event Event data from autocomplite
   */
  getOffers(event: any) {
    this.offerListOptions.filterId = event.query;
    this.offerListOptions.filterFullName = event.query;
    this.offerListOptions.filterDescription = event.query;

    this.restOffer.getOffers(this.offerListOptions).subscribe(data => {
      this.offers = data.data;
    });
  }

  /**
   * Load plans
   *
   * @param event Event data from autocomplite
   */
  getPlans(event: any) {
    this.planListOptions.filterTitle = event.query;
    this.planListOptions.filterDescription = event.query;

    this.restPlan.getPlans(this.planListOptions).subscribe((data: any) => {
      this.plans = data.data;
    });
  }

  /**
   * add topic
   */
  addTopic() {
    if (this.topicData.number) {
      this.topicData.meetingId = this.config.data.meetingId;
      if (this.topicData.offer) {
        this.topicData.offerId = this.topicData.offer.id;
      }

      if (this.topicData.plan) {
        this.topicData.planId = this.topicData.plan.id;
      }

      this.ref.close(this.topicData);
    }
  }

  /**
   * Cancel input form
   */
  cancel() {
    this.ref.close();
  }
}
