/**
 * Participants
 */
export interface IParticipant {
  id: number;
  fullName: string;
  address: string; // Address
  positionId: number; // position id
  phone: string;
  email: string;
  isDisciplinary: boolean;
}

/**
 * Participant list query
 */
export interface IParticipantListOptions {
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  filterFullName: string;
  filterAddress: string;
  filterPositionId: string;
  filterPhone: string;
  filterEmail: string;
  filterIsDisciplinary: string;
  sortField: string;
  sortOrder: number;
}

/**
 * default options
 */
export const defaultParticipantListOptions: IParticipantListOptions = {
  totalRecords: 0,
  pageNumber: 1,
  pageSize: 20,
  filterFullName: '',
  filterAddress: '',
  filterPositionId: '',
  filterPhone: '',
  filterEmail: '',
  filterIsDisciplinary: '',
  sortField: 'isDisciplinary',
  sortOrder: -1
};
