import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { OrderService } from '../../../services/order.service';

import { ITopic, ITopicListOptions, defaultTopicListOptions } from '../../../interfaces/topic.interface';

import CalendarLocaleHe from '../../../../locale/calendar.locale.he';
import { TopicService } from 'src/app/services/topic.service';

/**
 *
 */
@Component({
  selector: 'app-order-add',
  templateUrl: './order-add.component.html',
  styleUrls: ['./order-add.component.css']
})
export class OrderAddComponent implements OnInit {

  priorities = [];

  /**
   * locale for Calendar
   */
  he: any = CalendarLocaleHe;

  /**
   * Default topics options
   */
  topicListOptions: ITopicListOptions = defaultTopicListOptions;

  /**
   * topics for suggestions
   */
  topics: ITopic[] = [];

  /**
   * Selected topic from autocomplite
   */
  selectedTopic: ITopic;

  /**
   *
   */
  @Input() orderData = {
    id: 0,
    priority: 4,
    description: '',
    address: '',
    expirationDate: new Date(),
    actualDate: null,
    status: false
  };

  /**
   *
   * @param rest Position Service
   */
  constructor(
    public rest: OrderService,
    public restTopic: TopicService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
    this.priorities = [
      { label: 'בחר עדיפות', value: null }, // Select priority
      { label: '1', value: 1 },
      { label: '2', value: 2 },
      { label: '3', value: 3 },
      { label: '4', value: 4 },
    ];
  }

  /**
   *
   */
  ngOnInit() {
    this.topicListOptions = {
      ...this.topicListOptions,
      pageNumber: 1,
      pageSize: 10
    };
  }

  /**
   * add position
   */
  addOrder() {
    if (this.orderData.priority &&
      this.orderData.description &&
      this.orderData.address) {

      this.ref.close(this.orderData);
    }
  }

  /**
   * Get topics filtered by resolution number
   *
   * @param event event from autocomplete
   */
  getTopics(event) {
    this.topicListOptions.filterResolutionNumber = event.query;

    this.restTopic.getTopics(this.topicListOptions).subscribe((data: any) => {
      this.topics = data.data.filter((s: any) => (s.resolutionNumber.toLocaleLowerCase().indexOf(event.query.toLocaleLowerCase()) === 0));
    });
  }
}
