import { Component, OnInit, Input } from '@angular/core';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { IOffer, IOfferListOptions, defaultOfferListOptions } from '../../../interfaces/offer.interface';
import { OfferService } from '../../../services/offer.service';

/**
 * Add topic form
 */
@Component({
  selector: 'app-offer-link',
  templateUrl: './offer-link.component.html',
  styleUrls: ['./offer-link.component.css']
})
export class OfferLinkComponent implements OnInit {
  /**
   * Input form for offer
   */
  @Input() offerData = {
    id: 0 // offer id
    // title: '' // offer title
  };

  /**
   * List of offers
   */
  offers: IOffer[] = [];

  /**
   * Current offer
   */
  offer: IOffer;

  /**
   * Options for load offer list
   */
  offerListOptions: IOfferListOptions = defaultOfferListOptions;

  /**
   * Constructor
   *
   * @param rest Topic service
   * @param restOffer Offerss service
   * @param ref Reference to Dialog
   * @param config Dialog config
   */
  constructor(public rest: OfferService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  /**
   * Init component
   */
  ngOnInit() {
    this.offerListOptions = {
      ...this.offerListOptions,
      pageNumber: 1,
      pageSize: 10,
      isFree: 1,
      filterType: 1
    };
  }

  /**
   * Load offers
   *
   * @param event Event data from autocomplite
   */
  getOffers(event: any) {
    this.offerListOptions.filterId = event.query;
    this.offerListOptions.filterFullName = event.query;
    this.offerListOptions.filterDescription = event.query;

    this.rest.getOffers(this.offerListOptions).subscribe((data: any) => {
      this.offers = data.data;
    });
  }

  /**
   * Save offer and return
   */
  addOffer() {
    this.ref.close(this.offer);
  }
}
