import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { PlanService } from '../../../services/plan.service';
import { PlanStatuses } from 'src/app/interfaces/plan.interface';

import CalendarLocaleHe from '../../../../locale/calendar.locale.he';

/**
 * Add offer component
 */
@Component({
  selector: 'app-plan-add',
  templateUrl: './plan-add.component.html',
  styleUrls: ['./plan-add.component.css']
})
export class PlanAddComponent implements OnInit {
  /**
   * locale for Calendar
   */
  he: any = CalendarLocaleHe;

  /**
   * Form input data
   */
  @Input() planData = {
    id: 0,
    title: '',
    description: '',
    comment: '',
    status: null,
    date: null,
    file: null
  };

  /**
   * Form validation status
   */
  validation = false;

  /**
   * Plan Statuses
   */
  planStatuses = PlanStatuses;

  /**
   * Constructor
   *
   * @param rest offer Service
   * @param route route
   * @param router router
   */
  constructor(
    public rest: PlanService,
    private route: ActivatedRoute,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  /**
   * Init component
   */
  ngOnInit() {}

  /**
   * File uploader
   *
   * @param event event
   */
  fileUploader(event: any) {
    this.planData.file = event.files[0];
  }

  /**
   * Check description for validation
   */
  changeDescription() {
    if (this.planData.description) {
      this.validation = true;
    } else {
      this.validation = false;
    }
  }

  /**
   * Check offer, close popup and return offer object
   */
  addPlan() {
    if (this.planData.title && this.planData.description) {
      if (this.planData.date) {
        this.planData.date.setMinutes(this.planData.date.getMinutes() - this.planData.date.getTimezoneOffset());
      }

      this.ref.close(this.planData);
    }
  }
}
