import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { OfferService } from '../../../services/offer.service';

import CalendarLocaleHe from '../../../../locale/calendar.locale.he';

/**
 * Add offer component
 */
@Component({
  selector: 'app-offer-add',
  templateUrl: './offer-add.component.html',
  styleUrls: ['./offer-add.component.css']
})
export class OfferAddComponent implements OnInit {
  /**
   * Calendar localization
   */
  he: any = CalendarLocaleHe;

  /**
   * Form input data
   */
  @Input() offerData = {
    id: 0,
    // title: '',
    fullName: '',
    description: '',
    address: '',
    phone: '',
    type: '',
    date: null,
    addressRoadSign: '',
    notesRoadSign: '',
    file: null
  };

  /**
   * Form validation status
   */
  validation = false;

  /**
   * Constructor
   *
   * @param rest offer Service
   * @param route route
   * @param router router
   */
  constructor(
    public rest: OfferService,
    private route: ActivatedRoute,
    private router: Router,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  /**
   * Init component
   */
  ngOnInit() {}

  /**
   * File uploader
   *
   * @param event event
   */
  fileUploader(event: any) {
    this.offerData.file = event.files[0];
  }

  /**
   * Check description for validation
   */
  changeDescription() {
    if (this.offerData.description) {
      this.validation = true;
    } else {
      this.validation = false;
    }
  }

  /**
   * Check offer, close popup and return offer object
   */
  addOffer() {
    console.log(this.offerData);
    if (this.offerData.description && this.offerData.fullName) {
      if (this.offerData.date) {
        this.offerData.date.setMinutes(this.offerData.date.getMinutes() - this.offerData.date.getTimezoneOffset());
      }
      this.ref.close(this.offerData);
    }
  }
}
