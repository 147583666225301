import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService, MessageService, DialogService } from 'primeng/api';

import { TopicService } from 'src/app/services/topic.service';
import { ITopic, TopicStatuses } from 'src/app/interfaces/topic.interface';

import { IOffer } from 'src/app/interfaces/offer.interface';
import { OfferService } from '../../../services/offer.service';

import { I18nService } from '../../../services/i18n.service';

import { OrderService } from 'src/app/services/order.service';
import { IOrder } from 'src/app/interfaces/order.interface';
import { MenuService } from 'src/app/services/menu.service';
import { UserService } from 'src/app/services/user.service';
import { OrderAddComponent } from '../../order/order-add/order-add.component';

/**
 * View offer component
 */
@Component({
  selector: 'app-offer-view',
  templateUrl: './offer-view.component.html',
  styleUrls: ['./offer-view.component.css'],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class OfferViewComponent implements OnInit {
  /**
   * Current offer
   */
  offerData: IOffer;

  /**
   * list of Topics
   */
  topics: ITopic[] = [];

  /**
   * List of order for selected topic
   */
  orders: IOrder[] = null;

  /**
   * Offer Statuses
   */
  offerStatuses = TopicStatuses;

  /**
   * System busy - offer
   */
  busyOffer = true;

  /**
   * System busy - order
   */
  busyOrder = false;

  /**
   * Loading status
   */
  loading = true;

  /**
   * Selected topic
   */
  selectedTopic: ITopic;

  /**
   * Init component
   *
   * @param rest offer service
   * @param restTopic topic service
   * @param route active route
   * @param router router
   */
  constructor(
    public rest: OfferService,
    public restTopic: TopicService,
    public restOrder: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private i18nService: I18nService,
    public User: UserService,
    public menu: MenuService
  ) {
    this.menu.activeItem = null;
  }

  /**
   * init data
   */
  ngOnInit() {
    this.menu.activeItem = this.menu.items[1];
    this.getOffer();
    this.getTopicsByOffer();
    this.getAllOrders();
  }

  /**
   * Get current offer
   */
  getOffer() {
    this.rest.getOffer(this.route.snapshot.params.id).subscribe(data => {
      this.offerData = data;
    });
  }

  /**
   * Get topics for current offer
   */
  getTopicsByOffer() {
    this.rest.getTopicsByOffer(this.route.snapshot.params.id).subscribe(data => {
      this.topics = data;
      this.busyOffer = false;
    });
  }

  /**
   * Get all orders for offer
   */
  getAllOrders() {
    this.busyOrder = true;
    this.restOrder.getOrdersByOfferID(this.route.snapshot.params.id).subscribe(data => {
      this.orders = data;
      this.busyOrder = false;
    });
  }

  /**
   * Open accordion
   *
   * @param event Event
   */
  openAccordion(event) {
    this.busyOrder = true;
    this.selectedTopic = this.topics[event.index];
    this.restTopic.getOrdersByTopicId(this.topics[event.index].id).subscribe(data => {
      this.orders = data;
      this.busyOrder = false;
    });
  }

  /**
   * Close accordion event handler
   *
   * @param event Event
   */
  closeAccordion(event) {
    this.getAllOrders();
    this.selectedTopic = null;
  }

  /**
   * Go to order
   *
   * @param order order
   */
  view(order: IOrder) {
    this.router.navigate([`/order-view/${order.id}`]);
  }

  /**
   * remove order
   *
   * @param order Order object
   */
  delete(order: IOrder) {
    if (!this.User.isEditor) {
      return;
    }

    this.confirmationService.confirm({
      // message: 'Are you sure that you want to perform this action?',
      accept: () => {
        this.busyOrder = true;

        this.restOrder.deleteOrder(order).subscribe(
          result => {
            if (result.errors) {
              /*this.messageService.addAll(
                result.errors.map((i) => ({
                  severity: 'warn',
                  summary: 'Delete order',
                  detail: i
                })));*/
            } else {
              const index = this.orders.findIndex(el => order.id === el.id);
              this.orders.splice(index, 1);
              /*this.messageService.add({
                severity: 'success',
                summary: 'Order deleted',
                detail: 'Order successfully deleted'
              });*/

              this.busyOrder = false;
            }
          },
          err => {
            /*this.messageService.addAll(
              [{
                severity: 'warn',
                summary: 'Delete order',
                detail: err
              }]);*/
          }
        );
      }
    });
  }

  /**
   * Get offer status by id
   */
  getOfferStatus(statusId: number) {
    if (this.offerStatuses.find(o => o.value === statusId)) {
      return this.offerStatuses.find(o => o.value === statusId).label;
    } else {
      return 'Error';
    }
  }

  /**
   * add order
   */
  addOrder() {
    if (!this.User.isEditor) {
      return;
    }

    /* Open popup with form */
    const ref = this.dialogService.open(OrderAddComponent, {
      width: '90%',
      rtl: true,
      header: this.i18nService.get('addOrderHeader') // 'הוסף הוראת עבודה'
    });

    /* when popup closed, return filled participant */
    ref.onClose.subscribe((order: IOrder) => {
      if (order && this.selectedTopic) {
        order.topicId = this.selectedTopic.id;
        this.restOrder.addOrder(order).subscribe(
          result => {
            if (result.errors) {
              /*this.messageService.addAll(
              result.errors.map((i) => ({
                severity: 'warn',
                summary: this.i18nService.get('addOrderHeader'),
                detail: i
              })));*/
            } else {
              this.busyOrder = true;
              this.restTopic.getOrdersByTopicId(this.selectedTopic.id).subscribe(data => {
                this.orders = data;
                this.busyOrder = false;
              });
            }
          },
          err => {
            /*this.messageService.addAll(
            [{
              severity: 'warn',
              summary: this.i18nService.get('addOrderHeader'),
              detail: err
            }]);*/
          }
        );
      }
    });
  }
}
