import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { IParticipant, IParticipantListOptions, defaultParticipantListOptions } from '../../../interfaces/participant.interface';
import { ParticipantService } from '../../../services/participant.service';
import { ParticipantAddComponent } from '../participant-add/participant-add.component';
import { ParticipantViewComponent } from '../participant-view/participant-view.component';

import { Positions } from '../../../interfaces/position.interface';

import { I18nService } from '../../../services/i18n.service';

import { UserService } from '../../../services/user.service';

/**
 * PArticipants list
 */
@Component({
  selector: 'app-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.css'],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class ParticipantComponent implements OnInit {
  /**
   * List of participants
   */
  participants: Array<IParticipant> = [];

  /**
   * Selected participant
   */
  selectedParticipant: IParticipant;

  /**
   * Positions dropdown for filter
   */
  positionsDropdown = [];

  /**
   * Disciplinary filter dropdown
   */
  disciplinaryDropdown = [
    {
      label: 'הכל',
      value: ''
    },
    {
      label: 'כן',
      value: 'true'
    },
    {
      label: 'לא',
      value: 'false'
    }
  ];

  /**
   * State of loading data
   */
  loading = true;

  /**
   * Options for request participant list
   */
  participantListOptions: IParticipantListOptions = defaultParticipantListOptions;

  /**
   *
   * @param rest Participant service
   * @param confirmationService Confirmation popup
   * @param dialogService dialog popup
   * @param messageService Messages
   */
  constructor(
    public rest: ParticipantService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private i18nService: I18nService,
    public User: UserService
  ) {}

  /**
   * Init component
   */
  ngOnInit() {
    this.positionsDropdown = [
      {
        label: 'הכל',
        value: 0
      },
      ...Positions
    ];
  }

  /**
   * Lazy load data
   */
  loadData(event: any) {
    this.participantListOptions = {
      ...this.participantListOptions,
      pageNumber: event.first / event.rows + 1,
      pageSize: event.rows,
      filterFullName: event.filters.fullName ? event.filters.fullName.value : '',
      filterAddress: event.filters.address ? event.filters.address.value : '',
      filterPositionId: event.filters.positionId ? event.filters.positionId.value : '',
      filterPhone: event.filters.phone ? event.filters.phone.value : '',
      filterEmail: event.filters.email ? event.filters.email.value : '',
      filterIsDisciplinary: event.filters.isDisciplinary ? event.filters.isDisciplinary.value : '',
      sortField: event.sortField,
      sortOrder: event.sortOrder
    };
    this.getParticipants();
  }

  /**
   * Load all Participants
   */
  getParticipants() {
    this.loading = true;
    // this.participants = [];
    this.rest.getParticipants(this.participantListOptions).subscribe(data => {
      this.participants = data.data;
      this.participantListOptions = {
        ...this.participantListOptions,
        ...data.meta
      };
      this.loading = false;
    });
  }

  /**
   * Get position title by Id
   *
   * @param positionId Position ID
   */
  getPositionById(positionId: number): string {
    // return this.positions[positionId];
    if (this.positionsDropdown.find(p => p.value === positionId)) {
      return this.positionsDropdown.find(p => p.value === positionId).label;
    } else {
      return 'Error';
    }
  }

  /**
   * Add new participant
   */
  add() {
    if (!this.User.isEditor) {
      return;
    }

    /* Open popup with form */
    const ref = this.dialogService.open(ParticipantAddComponent, {
      header: this.i18nService.get('addParticipantHeader'), // @@addParticipant
      width: '90%',
      rtl: true
    });

    /* when popup closed, return filled participant */
    ref.onClose.subscribe((participant: IParticipant) => {
      if (participant) {
        this.loading = true;

        this.rest.addParticipant(participant).subscribe(
          result => {
            if (result.errors) {
              /*this.messageService.addAll(
              result.errors.map((i) => ({
                severity: 'warn',
                summary: this.i18nService.get('addParticipantHeader'),
                detail: i
              })));*/
            } else {
              this.participants.push(participant);
            }
            this.getParticipants();
          },
          err => {
            /*this.messageService.addAll(
            [{
              severity: 'warn',
              summary: this.i18nService.get('addParticipantHeader'),
              detail: err
            }]);*/
            this.getParticipants();
          }
        );
      }
    });
  }

  /**
   * View current selected participant
   */
  view() {
    if (!this.User.isEditor) {
      return;
    }

    if (this.selectedParticipant) {
      /* open popup for edit participant */
      const ref = this.dialogService.open(ParticipantViewComponent, {
        data: {
          participant: this.selectedParticipant
        },
        header: this.i18nService.get('viewParticipantHeader'), // @@viewParticipant
        width: '90%',
        rtl: true
      });

      /* return updated participant, when clicked Save in popup */
      ref.onClose.subscribe((participant: IParticipant) => {
        if (participant) {
          this.loading = true;

          this.rest.updateParticipant(participant).subscribe(
            result => {
              if (result.errors) {
                /*this.messageService.addAll(
                result.errors.map((i) => ({
                  severity: 'warn',
                  summary: this.i18nService.get('viewParticipantHeader'),
                  detail: i
                })));*/
              }
              this.getParticipants();
            },
            err => {
              /*this.messageService.addAll(
              [{
                severity: 'warn',
                summary: this.i18nService.get('viewParticipantHeader'),
                detail: err
              }]);*/
              this.getParticipants();
            }
          );
        }
      });
    }
  }

  /**
   * Remove participant with confirm
   *
   * @param participant Participant object for delete
   */
  delete(participant: IParticipant) {
    if (!this.User.isEditor) {
      return;
    }

    this.confirmationService.confirm({
      message: 'בלחיצה על "מחיקה" המערכת תמחק את איש קשר זה מהמערכת', // @@deleteParticipantMessage
      accept: () => {
        this.loading = true;

        this.rest.deleteParticipant(participant).subscribe(
          result => {
            if (result.errors) {
              this.messageService.addAll(
                result.errors.map(i => ({
                  severity: 'warn',
                  summary: this.i18nService.get('deleteParticipant'),
                  detail: i,
                  life: 10000
                }))
              );
            }
            this.getParticipants();
          },
          err => {
            this.messageService.addAll([
              {
                severity: 'warn',
                summary: this.i18nService.get('deleteParticipant'),
                detail: err,
                life: 10000
              }
            ]);
            this.getParticipants();
          }
        );
      }
    });
  }
}
