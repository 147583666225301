import { Component, OnInit, DoCheck } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MenuItem } from 'primeng/api';

import { MenuService } from './services/menu.service';

import { environment } from '../environments/environment';

import { DeviceDetectorService } from 'ngx-device-detector';

import { VERSION } from '@angular/core';

import { UserService } from './services/user.service';

declare var geodaPageUrl: any;
declare var homePageUrl: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, DoCheck {
  /**
   * Title
   */
  title = 'geodata';

  /**
   * Menu URL
   */
  geodaPageUrl: string = geodaPageUrl;
  homePageUrl: string = homePageUrl;

  /**
   * Browser Name
   */
  browser: string;

  /**
   * Browser Version
   */
  browserVersion: string;

  /**
   * Build Date
   */
  buildDate: Date;

  /**
   * Angular version
   */
  version: string;

  /**
   *
   */
  activeItem: MenuItem;

  /**
   * Constructor
   *
   * @param route route
   * @param router router
   */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public menu: MenuService,
    private deviceService: DeviceDetectorService,
    public User: UserService
  ) {
    // console.log(window);
  }

  ngDoCheck() {
    this.activeItem = this.menu.activeItem;
    // console.log('App activeItem', this.activeItem);
  }

  /**
   * Init component
   */
  ngOnInit() {
    if (window.location.pathname === '/orders') {
      this.menu.setInventory();
    }

    this.browser = this.deviceService.browser;
    this.browserVersion = this.deviceService.browser_version;
    this.buildDate = environment.buildDate;
    this.version = 'Angular ' + VERSION.major + ' HTML5';
  }
}
