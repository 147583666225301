import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { ITopic, ITopicListOptions, defaultTopicListOptions } from '../interfaces/topic.interface';

import { environment } from 'src/environments/environment';

/**
 * endpoints
 * GET /api/topic/list
 * GET /api/topic/get/1
 * POST /api/topic/add
 * POST /api/topic/update
 * POST /api/topic/delete
 */
const endpoint = environment.endpoint + '/topic';

/**
 * HTTP options
 */
const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  })
};

/**
 * Topic service
 */
@Injectable({
  providedIn: 'root'
})
export class TopicService {

  /**
   * Inject http client
   *
   * @param http HTTP Client
   */
  constructor(private http: HttpClient) { }

  /**
   * Extract server data
   *
   * @param res server response
   */
  private extractData(res: Response) {
    console.log('Response:', res);
    const body = res;
    return body || {};
  }

  /**
   * Topic list
   *
   * @param query Query options
   */
  getTopics(query: ITopicListOptions = defaultTopicListOptions): Observable<any> {

    query = {
      ...defaultTopicListOptions,
      ...query
    };

    const getRequest = `${endpoint}/list?pageNumber=${
      query.pageNumber ? query.pageNumber : 1
      }&pageSize=${
      query.pageSize ? query.pageSize : 10
      }&filterNumber=${
      query.filterNumber
      }&filterName=${
      query.filterName
      }&filterResolutionNumber=${
      query.filterResolutionNumber
      }&filterStatus=${
      query.filterStatus.toString() !== '0' ? query.filterStatus : ''
      }&sortField=${
      query.sortField
      }&sortOrder=${
      query.sortOrder
      }`;

    return this.http.get(getRequest, httpOptions).pipe(
      map(this.extractData));
  }

  /**
   * Get topic by id
   *
   * @param id topic id
   */
  getTopic(id: number): Observable<any> {
    return this.http.get(endpoint + '/get/' + id).pipe(
      map(this.extractData));
  }

  /**
   * Add new topic
   *
   * @param topic Topic object
   */
  addTopic(topic: ITopic): Observable<any> {
    return this.http.post<any>(endpoint + '/add', JSON.stringify(topic), httpOptions).pipe(
      tap((t: ITopic) => console.log(`added topic id=${t.id}`)),
      catchError(this.handleError<any>('addTopic'))
    );
  }

  /**
   * Update topic
   *
   * @param topic Topic object
   */
  updateTopic(topic: ITopic): Observable<any> {
    return this.http.post(endpoint + '/update', JSON.stringify(topic), httpOptions).pipe(
      tap((t: ITopic) => console.log(`updated topic id=${t.id}`)),
      catchError(this.handleError<any>('updateTopic'))
    );
  }

  /**
   * Remove topic
   *
   * @param topic Topic object
   */
  deleteTopic(topicId: number): Observable<any> {
    return this.http.post<any>(endpoint + '/delete', JSON.stringify({ id: topicId }), httpOptions).pipe(
      tap((t: ITopic) => console.log(`deleted topic id=${t.id}`)),
      catchError(this.handleError<any>('deleteTopic'))
    );
  }

  /**
   * Get orders by topic
   *
   * @param topicId Topic id
   */
  getOrdersByTopicId(topicId: number): Observable<any> {
    return this.http.get(endpoint + '/getOrdersByTopicID/' + topicId).pipe(
      map(this.extractData));
  }

  /**
   * Errors handler
   *
   * @param operation operation
   * @param result result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
