import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* MEETING */
import { MeetingComponent } from './components/meeting/meeting/meeting.component';
import { MeetingAddComponent } from './components/meeting/meeting-add/meeting-add.component';
import { MeetingViewParticipantsComponent } from './components/meeting/meeting-view-participants/meeting-view-participants.component';
import { MeetingViewTopicsComponent } from './components/meeting/meeting-view-topics/meeting-view-topics.component';
import { MeetingViewProtocolsComponent } from './components/meeting/meeting-view-protocols/meeting-view-protocols.component';

/* PARTICIPANT */
import { ParticipantComponent } from './components/participant/participant/participant.component';

/* ORDER */
import { OrderComponent } from './components/order/order/order.component';
import { OrderViewComponent } from './components/order/order-view/order-view.component';
import { OrderAddComponent } from './components/order/order-add/order-add.component';

/* OFFER */
import { OfferComponent } from './components/offer/offer/offer.component';
import { OfferViewComponent } from './components/offer/offer-view/offer-view.component';
import { OfferAddComponent } from './components/offer/offer-add/offer-add.component';

/* PLANS */
import { PlanComponent } from './components/plan/plan/plan.component';
import { PlanEditComponent } from './components/plan/plan-edit/plan-edit.component';
import { PlanAddComponent } from './components/plan/plan-add/plan-add.component';
import { PlanViewComponent } from './components/plan/plan-view/plan-view.component';

const routes: Routes = [
  /**
   * Meeting routing
   */
  {
    path: 'meetings',
    component: MeetingComponent,
    data: { title: 'Meeting List' }
  },
  {
    path: 'meeting-view/:id/participants',
    component: MeetingViewParticipantsComponent,
    data: { title: 'Meeting Detail' }
  },
  {
    path: 'meeting-view/:id/topics',
    component: MeetingViewTopicsComponent,
    data: { title: 'Meeting Detail' }
  },
  {
    path: 'meeting-view/:id/topics/:topicId',
    component: MeetingViewTopicsComponent,
    data: { title: 'Meeting Detail' }
  },
  {
    path: 'meeting-view/:id/protocols',
    component: MeetingViewProtocolsComponent,
    data: { title: 'Meeting Detail' }
  },
  {
    path: 'meeting-add',
    component: MeetingAddComponent,
    data: { title: 'Meeting Add' }
  },
  /**
   * Participant routing
   */
  {
    path: 'participants',
    component: ParticipantComponent,
    data: { title: 'Participant List' }
  },
  /**
   * Order routing
   */
  {
    path: 'orders',
    component: OrderComponent,
    data: { title: 'Order List' }
  },
  {
    path: 'orders/:id',
    component: OrderComponent,
    data: { title: 'Order List' }
  },
  {
    path: 'order-view/:id',
    component: OrderViewComponent,
    data: { title: 'Order Detail' }
  },
  {
    path: 'order-add',
    component: OrderAddComponent,
    data: { title: 'Order Add' }
  },
  /**
   * Offer routing
   */
  {
    path: 'offers',
    component: OfferComponent,
    data: { title: 'Offer List' }
  },
  {
    path: 'offer-view/:id',
    component: OfferViewComponent,
    data: { title: 'Offer Detail' }
  },
  {
    path: 'offer-add',
    component: OfferAddComponent,
    data: { title: 'Offer Add' }
  },
  /**
   * Plans routing
   */
  {
    path: 'plans',
    component: PlanComponent,
    data: { title: 'Plan List' }
  },
  {
    path: 'plan-edit/:id',
    component: PlanEditComponent,
    data: { title: 'Plan Detail' }
  },
  {
    path: 'plan-view/:id',
    component: PlanViewComponent,
    data: { title: 'Plan Detail' }
  },
  {
    path: 'plan-add',
    component: PlanAddComponent,
    data: { title: 'Plan Add' }
  },
  /**
   *
   */
  {
    path: '',
    redirectTo: '/meetings',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
