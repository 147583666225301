import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { OfferService } from '../../../services/offer.service';
import { IOffer, IOfferListOptions, defaultOfferListOptions, OfferStatuses } from '../../../interfaces/offer.interface';
import { OfferAddComponent } from '../offer-add/offer-add.component';
import { OfferEditComponent } from '../offer-edit/offer-edit.component';

import { I18nService } from '../../../services/i18n.service';

import { UserService } from '../../../services/user.service';

import { environment } from 'src/environments/environment';

/**
 *
 */
const endpoint = environment.endpoint + '/offer';

/**
 * Offer component
 */
@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css'],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class OfferComponent implements OnInit {
  /**
   * List of offers
   */
  offers: Array<IOffer> = [];

  /**
   * Selected offer
   */
  selectedOffer: IOffer;

  /**
   * Loading data mode
   */
  loading = true;

  /**
   * Request options for offer list
   */
  offerListOptions: IOfferListOptions = defaultOfferListOptions;

  /**
   * Dropdown Offer Statuses
   */
  dropdownStatuses = [];

  /**
   * Offer Statuses
   */
  offerStatuses = OfferStatuses;

  /**
   *
   * @param rest Order service
   * @param route route
   * @param router router
   * @param confirmationService confirm
   */
  constructor(
    public rest: OfferService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private i18nService: I18nService,
    public User: UserService,
    public http: HttpClient
  ) {}

  /**
   *
   */
  ngOnInit() {
    this.dropdownStatuses = [
      {
        label: 'הכל',
        value: 0
      },
      ...OfferStatuses
    ];
  }

  /**
   * Lazy load
   */
  loadData(event) {
    this.offerListOptions = {
      ...this.offerListOptions,
      pageNumber: event.first / event.rows + 1,
      pageSize: event.rows,
      filterId: event.filters.id ? event.filters.id.value : '',
      filterFullName: event.filters.fullName ? event.filters.fullName.value : '',
      filterDescription: event.filters.description ? event.filters.description.value : '',
      filterAddress: event.filters.address ? event.filters.address.value : '',
      filterPhone: event.filters.phone ? event.filters.phone.value : '',
      filterStatus: event.filters.status ? event.filters.status.value : '',
      sortField: event.sortField,
      sortOrder: event.sortOrder
    };
    this.getOffers();
  }

  /**
   * Load all offers
   */
  getOffers() {
    this.loading = true;
    // this.offers = [];
    this.rest.getOffers(this.offerListOptions).subscribe(data => {
      this.offers = data.data;
      this.offerListOptions = {
        ...this.offerListOptions,
        ...data.meta
      };
      this.loading = false;
    });
  }

  /**
   * Add offer
   */
  add() {
    if (!this.User.isEditor) {
      return;
    }

    /* Open popup with form */
    const ref = this.dialogService.open(OfferAddComponent, {
      header: this.i18nService.get('addOfferHeader'),
      width: '90%',
      rtl: true
    });

    /* when popup closed, return filled offer */
    ref.onClose.subscribe((offer: IOffer) => {
      // console.log(offer);

      if (offer) {
        this.loading = true;

        const formData = new FormData();

        formData.append('fullName', offer.fullName);
        formData.append('description', offer.description);
        formData.append('address', offer.address);
        formData.append('phone', offer.phone);
        formData.append('type', offer.type);
        formData.append('date', offer.date ? offer.date.toISOString() : new Date().toISOString());
        formData.append('addressRoadSign', offer.addressRoadSign);
        formData.append('notesRoadSign', offer.notesRoadSign);
        // formData.append('status', offer.status.toString());
        if (offer.file) {
          formData.append('file', offer.file, offer.file.name);
        }

        this.http
          .post(endpoint + '/add', formData, {
            reportProgress: true,
            observe: 'events'
          })
          .subscribe(
            event => {
              // console.log(event);
              if (event.type === 3) {
                this.getOffers();
              }
            },
            error => {
              console.log('error:', error);
            }
          );
      }
    });
  }

  /**
   * View offer
   */
  view(): void {
    this.router.navigate([`/offer-view/${this.selectedOffer.id}`]);
  }

  /**
   * Edit offer
   */
  edit() {
    if (!this.User.isEditor) {
      return;
    }

    if (this.selectedOffer) {
      /* open popup for edit offer */
      const ref = this.dialogService.open(OfferEditComponent, {
        data: {
          offer: this.selectedOffer
        },
        header: this.i18nService.get('editOfferHeader'),
        width: '90%',
        rtl: true
      });

      /* return updated offer, when clicked Save in popup */
      ref.onClose.subscribe((offer: IOffer) => {
        if (offer) {
          this.loading = true;

          console.log(offer);

          const formData = new FormData();

          formData.append('id', offer.id.toString());
          formData.append('fullName', offer.fullName);
          formData.append('description', offer.description);
          formData.append('address', offer.address || '');
          formData.append('phone', offer.phone || '');
          formData.append('type', offer.type || '');
          formData.append('date', offer.date ? offer.date.toISOString() : new Date().toISOString());
          formData.append('addressRoadSign', offer.addressRoadSign || '');
          formData.append('notesRoadSign', offer.notesRoadSign || '');
          formData.append('status', offer.status.toString());

          if (offer.file) {
            formData.append('file', offer.file, offer.file.name);
          }

          this.http
            .post(endpoint + '/update', formData, {
              reportProgress: true,
              observe: 'events'
            })
            .subscribe(
              event => {
                // console.log(event);
                if (event.type === 3) {
                  this.getOffers();
                }
              },
              error => {
                console.log('error:', error);
                this.messageService.addAll([
                  {
                    severity: 'warn',
                    summary: this.i18nService.get('editOfferHeader'),
                    detail: error
                  }
                ]);
              }
            );
        }
      });
    }
  }

  /**
   * remove offer
   *
   * @param offer Offer object
   */
  delete(offer: IOffer) {
    if (!this.User.isEditor) {
      return;
    }

    this.confirmationService.confirm({
      message: this.i18nService.get('confirmDeleteOfferMessage'), // 'Are you sure that you want to perform this action?',
      accept: () => {
        this.loading = true;

        this.rest.deleteOffer(offer).subscribe(
          result => {
            if (result.errors) {
              /*this.messageService.addAll(
                result.errors.map((i) => ({
                  severity: 'warn',
                  summary: 'Delete offer',
                  detail: i
                })));*/
            } else {
              this.getOffers();
            }
          },
          err => {
            /*this.messageService.addAll(
              [{
                severity: 'warn',
                summary: 'Delete offer',
                detail: err
              }]);*/
          }
        );
      }
    });
  }

  /**
   * Get offer status by id
   */
  getOfferStatus(statusId: number) {
    // return this.offerStatuses.find(offer => offer.value === status);
    if (this.offerStatuses.find(o => o.value === statusId)) {
      return this.offerStatuses.find(o => o.value === statusId).label;
    } else {
      return 'Error';
    }
  }
}
