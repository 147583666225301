import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { IMeeting, IMeetingListOptions, defaultMeetingListOptions } from '../interfaces/meeting.interface';

import { environment } from 'src/environments/environment';

/**
 * endpoints
 * GET /api/meeting/list
 * GET /api/meeting/get/1
 * POST /api/meeting/add
 * POST /api/meeting/update
 * POST /api/meeting/delete
 * POST /api/meeting/addParticipant
 * POST /api/meeting/deleteParticipant
 */
const endpoint = environment.endpoint + '/meeting'; // 'https://geodata.tahion.by/api/meeting';

/**
 * HTTP options
 */
const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  })
};

/**
 * Meeting service
 */
@Injectable({
  providedIn: 'root'
})
export class MeetingService {
  /**
   * Inject http client
   *
   * @param http HTTP Client
   */
  constructor(private http: HttpClient) {}

  /**
   * Extract server data
   *
   * @param res server response
   */
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  /**
   * List of meetings
   *
   * @param query options for request list
   */
  getMeetings(query: IMeetingListOptions = defaultMeetingListOptions): Observable<any> {
    query = {
      ...defaultMeetingListOptions,
      ...query
    };

    const getRequest = `${endpoint}/list?pageNumber=${query.pageNumber ? query.pageNumber : 1}&pageSize=${
      query.pageSize ? query.pageSize : 10
    }&filterDate=${query.filterDate}&filterHasProtocols=${query.filterProtocols}&filterHasOrders=${query.filterOrders}&sortField=${
      query.sortField
    }&sortOrder=${query.sortOrder}`;

    return this.http.get(getRequest, httpOptions).pipe(map(this.extractData));
  }

  /**
   * Get meeting by id
   *
   * @param id meeting id
   */
  getMeeting(id: number): Observable<any> {
    return this.http.get(endpoint + '/get/' + id).pipe(map(this.extractData));
  }

  /**
   * Add new meeting
   *
   * @param meeting New meeting object
   */
  addMeeting(meeting: IMeeting): Observable<any> {
    return this.http.post<any>(endpoint + '/add', JSON.stringify(meeting), httpOptions).pipe(
      tap((m: IMeeting) => console.log(`added meeting id=${m.id}`)),
      catchError(this.handleError<any>('addMeeting'))
    );
  }

  /**
   * Update meeting
   *
   * @param meeting meeting object
   */
  updateMeeting(meeting: IMeeting): Observable<any> {
    return this.http.post(endpoint + '/update', JSON.stringify(meeting), httpOptions).pipe(
      tap((m: IMeeting) => console.log(`updated meeting id=${m.id}`)),
      catchError(this.handleError<any>('updateMeeting'))
    );
  }

  /**
   * Remove meeting
   *
   * @param meeting meeting
   */
  deleteMeeting(meeting: IMeeting): Observable<any> {
    return this.http.post<any>(endpoint + '/delete', JSON.stringify(meeting), httpOptions).pipe(
      tap((m: IMeeting) => console.log(`deleted meeting id=${m.id}`)),
      catchError(this.handleError<any>('deleteMeeting'))
    );
  }

  /**
   * Get list of participants for selected meeting
   *
   * @param meetingId meeting id
   */
  getParticipantsByMeeting(meetingId: number): Observable<any> {
    return this.http.get(endpoint + '/getParticipants/' + meetingId).pipe(map(this.extractData));
  }

  /**
   * Get list of topics for selected meeting
   *
   * @param meetingId meeting id
   */
  getTopicsByMeeting(meetingId: number): Observable<any> {
    return this.http.get(endpoint + '/getTopics/' + meetingId).pipe(map(this.extractData));
  }

  /**
   * Get list of protocols for selected meeting
   *
   * @param meetingId meeting id
   */
  getProtocolsByMeeting(meetingId: number): Observable<any> {
    return this.http.get(endpoint + '/getProtocols/' + meetingId).pipe(map(this.extractData));
  }

  /**
   * Generating protocol
   *
   * @param meetingId meeting id
   * @param comment protocol comment
   */
  protocolGenerate(meetingId: number, comment: string) {
    return this.http
      .post<any>(
        endpoint + '/protocolGenerate',
        JSON.stringify({
          meetingId,
          comment
        }),
        httpOptions
      )
      .pipe(
        tap(p => console.log(`Protocol generated id=${p.id}`)),
        catchError(this.handleError<any>('Protocol generated'))
      );
  }

  /**
   * Delete protocol
   */
  protocolDelete(protocolId: number) {
    return this.http
      .post<any>(
        endpoint + '/protocolDelete',
        JSON.stringify({
          id: protocolId
        }),
        httpOptions
      )
      .pipe(
        tap(p => console.log(`Protocol deleted id=${p.id}`)),
        catchError(this.handleError<any>('Protocol deleted'))
      );
  }

  /**
   * Errors
   *
   * @param operation operation
   * @param result result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead

      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
