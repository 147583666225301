import { Injectable } from '@angular/core';
import { Xliff } from '@angular/compiler';
// You can also import {Xliff} or {Xtb} instead of {Xliff2}, depending on your project configurations

/**
 * Loader
 */
declare const require;

/**
 * File with translation phrases
 */
const content = require('raw-loader!../../locale/messages.he.xlf');

/**
 * Translations service
 */
@Injectable({
  providedIn: 'root'
})
export class I18nService {

  /**
   * translations file
   */
  private readonly xliff: any = new Xliff().load(content, '');

  /**
   * Get translation by key phrase
   *
   * @param key phrase id
   */
  get(key: string): string {
    return this.xliff.i18nNodesByMsgId[key][0].value;
  }
}
