import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { IProtocol } from '../interfaces/protocol.interface';

import { environment } from 'src/environments/environment';

/**
 * endpoints
 * GET /api/protocol/list
 * POST /api/protocol/generate
 * POST /api/protocol/addSigned/<id>
 * POST /api/protocol/delete
 */
const endpoint = environment.endpoint + '/protocol';

/**
 * HTTP options
 */
const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  })
};

/**
 * Protocols
 */
@Injectable({
  providedIn: 'root'
})
export class ProtocolService {

  /**
   * Inject http client
   *
   * @param http http client
   */
  constructor(private http: HttpClient) { }

  /**
   * Extract server data
   *
   * @param res server response
   */
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  /**
   * Protocols list by meeting id
   *
   * @param meetingId Meeting Id
   */
  getProtocols(meetingId: number): Observable<any> {
    return this.http.get(endpoint + '/list/' + meetingId).pipe(
      map(this.extractData));
  }


  /**
   * Generate new protocol
   *
   * @param meetingId meeting id
   * @param comment comment for protocol
   */
  generateProtocol(meetingId: number, comment: string): Observable<any> {
    return this.http.post<any>(endpoint + '/generate', JSON.stringify({
      meetingId,
      comment
    }), httpOptions).pipe(
      tap((p) => console.log(`Generated protocol id=${p.id}`)),
      catchError(this.handleError<any>('generateProtocol'))
    );
  }

  /**
   * Remove protocol
   *
   * @param id protocol id
   */
  deleteProtocol(id: number): Observable<any> {
    return this.http.post<any>(endpoint + '/delete', JSON.stringify({ id }), httpOptions).pipe(
      tap((p) => console.log(`deleted protocol id=${p.id}`)),
      catchError(this.handleError<any>('deleteProtocol'))
    );
  }

  /**
   * error handler
   * @param operation operation
   * @param result result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead

      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
