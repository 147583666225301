import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/api';
import { MessageService } from 'primeng/api';

import { OrderService } from '../../../services/order.service';
import { IOrder, IOrderListOptions, defaultOrderListOptions, OrderStatuses } from '../../../interfaces/order.interface';
import { OrderAddComponent } from '../order-add/order-add.component';
import { OrderViewComponent } from '../order-view/order-view.component';

import { I18nService } from '../../../services/i18n.service';

import { UserService } from '../../../services/user.service';
import { MenuService } from 'src/app/services/menu.service';
import { OrderDownloadComponent } from '../order-download/order-download.component';

import { environment } from 'src/environments/environment';

import CalendarLocaleHe from '../../../../locale/calendar.locale.he';
import { CompileShallowModuleMetadata } from '@angular/compiler';

/**
 *
 */
const endpoint = environment.endpoint + '/order';

/**
 * Order Component
 */
@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css'],
  providers: [ConfirmationService, DialogService, MessageService]
})
export class OrderComponent implements OnInit {
  /**
   * Calendar localization
   */
  he: any = CalendarLocaleHe;

  /**
   * List of orders
   */
  orders: Array<IOrder> = [];

  /**
   * Selected order
   */
  selectedOrder: IOrder;

  /**
   * Loading status
   */
  loading = true;

  /**
   * Options for order request
   */
  orderListOptions: IOrderListOptions = defaultOrderListOptions;

  /**
   * Filter priority list
   */
  priorityList = [
    { label: 'הכל', value: '' },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 }
  ];

  /**
   * Order statuses
   */
  orderStatuses = OrderStatuses;

  /**
   * Constructor
   *
   * @param rest Order service
   * @param route route
   * @param router router
   * @param confirmationService confirm
   */
  constructor(
    public rest: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public dialogService: DialogService,
    private i18nService: I18nService,
    public User: UserService,
    public menu: MenuService
  ) {
    this.menu.activeItem = null;
  }

  /**
   * Init component
   */
  ngOnInit() {
    this.menu.activeItem = this.menu.items[1];
  }

  /**
   * lazy load
   */
  loadData(event: any) {
    let filterExpirationDate: Date = null;
    if (event.filters.expirationDate && event.filters.expirationDate.value instanceof Date) {
      filterExpirationDate = new Date(event.filters.expirationDate.value);
      filterExpirationDate.setMinutes(filterExpirationDate.getMinutes() - filterExpirationDate.getTimezoneOffset());
    }

    let filterActualDate: Date = null;
    if (event.filters.actualDate && event.filters.actualDate.value instanceof Date) {
      filterActualDate = new Date(event.filters.actualDate.value);
      filterActualDate.setMinutes(filterActualDate.getMinutes() - filterActualDate.getTimezoneOffset());
    }

    this.orderListOptions = {
      ...this.orderListOptions,
      pageNumber: event.first / event.rows + 1,
      pageSize: event.rows,
      filterPriority: event.filters.priority ? event.filters.priority.value : '',
      filterDescription: event.filters.description ? event.filters.description.value : '',
      filterAddress: event.filters.address ? event.filters.address.value : '',
      filterExpirationDate: filterExpirationDate ? filterExpirationDate.toISOString().slice(0, 10) : '',
      filterActualDate: filterActualDate ? filterActualDate.toISOString().slice(0, 10) : '',
      sortField: event.sortField,
      sortOrder: event.sortOrder
    };

    this.getOrders();
  }

  /**
   * load all orders
   */
  getOrders() {
    this.orders = [];
    this.rest.getOrders(this.orderListOptions).subscribe(data => {
      this.orders = data.data;
      this.orderListOptions = {
        ...this.orderListOptions,
        ...data.meta
      };
      this.loading = false;

      if (this.route.snapshot.params.id) {
        this.menu.items = [
          {
            label: 'אינוונטר'
          },
          {
            label: 'הוראות עבודה',
            routerLink: ['/orders']
          }
        ];

        this.menu.item = 1;
        this.menu._activeItem = this.menu.items[1];
        this.selectedOrder = this.orders.find(o => o.id.toString() === this.route.snapshot.params.id);
        console.log();
      }
    });
  }

  /**
   * add order
   */
  add() {
    if (!this.User.isEditor) {
      return;
    }

    /* Open popup with form */
    const ref = this.dialogService.open(OrderAddComponent, {
      width: '90%',
      rtl: true,
      header: this.i18nService.get('addOrderHeader') // 'הוסף הוראת עבודה'
    });

    /* when popup closed, return filled participant */
    ref.onClose.subscribe((order: IOrder) => {
      if (order) {
        this.loading = true;

        this.rest.addOrder(order).subscribe(
          result => {
            if (result.errors) {
              /*this.messageService.addAll(
              result.errors.map((i) => ({
                severity: 'warn',
                summary: this.i18nService.get('addOrderHeader'),
                detail: i
              })));*/
            }
            this.getOrders();
          },
          err => {
            /*this.messageService.addAll(
            [{
              severity: 'warn',
              summary: this.i18nService.get('addOrderHeader'),
              detail: err
            }]);*/
            this.getOrders();
          }
        );
      }
    });
  }

  /**
   * view order
   */
  view() {
    if (!this.User.isEditor) {
      return;
    }

    if (this.selectedOrder) {
      /* open popup for edit participant */
      const ref = this.dialogService.open(OrderViewComponent, {
        data: {
          order: this.selectedOrder
        },
        header: this.i18nService.get('viewOrderHeader'), // 'פרטי הוראת עבודה',
        width: '90%',
        rtl: true
      });

      /* return updated participant, when clicked Save in popup */
      ref.onClose.subscribe((order: IOrder) => {
        if (order) {
          this.loading = true;

          this.rest.updateOrder(order).subscribe(
            result => {
              if (result.errors) {
                /*this.messageService.addAll(
                result.errors.map((i) => ({
                  severity: 'warn',
                  summary: this.i18nService.get('viewOrderHeader'),
                  detail: i
                })));*/
              }
              this.getOrders();
            },
            err => {
              /*this.messageService.addAll(
              [{
                severity: 'warn',
                summary: this.i18nService.get('viewOrderHeader'),
                detail: err
              }]);*/
              this.getOrders();
            }
          );
        }
      });
    }
  }

  /**
   * download order
   */
  downloadOrders() {
    if (!this.User.isEditor) {
      return;
    }

    const ref = this.dialogService.open(OrderDownloadComponent, {
      header: this.i18nService.get('downloadOrders'),
      width: '420px',
      rtl: true
    });

    /* return updated participant, when clicked Save in popup */
    ref.onClose.subscribe((order: IOrder) => {
      if (order) {
        let from = '';
        let fromMounth = '';
        let to = '';
        let toMounth = '';

        if (order.dateFrom) {
          fromMounth =
            order.dateFrom.getMonth() + 1 < 10 ? '0' + (order.dateFrom.getMonth() + 1) : (order.dateFrom.getMonth() + 1).toString();

          from = `${order.dateFrom.getFullYear()}-${fromMounth}-${order.dateFrom.getDate()}`;
        }

        if (order.dateTo) {
          toMounth = order.dateTo.getMonth() + 1 < 10 ? '0' + (order.dateTo.getMonth() + 1) : (order.dateTo.getMonth() + 1).toString();

          to = `${order.dateTo.getFullYear()}-${toMounth}-${order.dateTo.getDate()}`;
        }

        window.location.href = `${endpoint}/listXls?status=${order.status}&dateFrom=${from}&dateTo=${to}`;
      }
    });
  }

  /**
   * remove order
   */
  delete(order: IOrder) {
    if (!this.User.isEditor) {
      return;
    }

    this.confirmationService.confirm({
      message: this.i18nService.get('confirmDeleteOrderMessage'),
      accept: () => {
        this.loading = true;

        this.rest.deleteOrder(order).subscribe(
          result => {
            if (result.errors) {
              /*this.messageService.addAll(
                result.errors.map((i) => ({
                  severity: 'warn',
                  summary: this.i18nService.get('confirmDeleteOrder'),
                  detail: i
                })));*/
            }
            this.getOrders();
          },
          err => {
            /*this.messageService.addAll(
              [{
                severity: 'warn',
                summary: this.i18nService.get('confirmDeleteOrder'),
                detail: err
              }]);*/
            this.getOrders();
          }
        );
      }
    });
  }

  /**
   * Get offer status by id
   */
  getOrderStatus(statusId: boolean) {
    if (statusId) {
      return this.orderStatuses[3].label;
    } else {
      return this.orderStatuses[2].label;
    }
  }
}
