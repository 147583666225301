import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

/**
 * GDAuth
 */
declare var GDAuth: any;

/**
 * Editor scope
 */
const editorScope = 'SignMNG.API.Editor';

/**
 * Viewer scope
 */
const viewerScope = 'SignMNG.API.Editor';

/**
 * User service
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {

  /**
   * Inner editor property
   */
  private isEditorProperty = false;

  /**
   * Inner viewer property
   */
  private isViewerProperty = true;

  /**
   * List of current scopes
   */
  scopes: string[] = [];

  /**
   * User Name
   */
  userName: string;

  /**
   * Customer logo
   */
  customerLogo: string;

  /**
   * Get auth data from global object GDAuth
   */
  constructor() {

    /*if (environment.production) {
      if (GDAuth.cache.token) {
        this.scopes = GDAuth.config.scope.split(' ');
        this.isEditorProperty = this.scopes.includes(editorScope);
        // this.isViewerProperty = this.scopes.includes(viewerScope);
      } else {
        console.log('Need auth');
        // TODE
        // redirect to login page
      }
    } else {
      this.isEditorProperty = true;*/


    GDAuth.get((auth) => {

      // console.log(auth);

      if (!auth) {
        // console.log('No scope');
        window.location.href = 'https://geodata.co.il/';
        // TODO
      }

      if (auth.scope) {
        // console.log(auth.user.name);
        this.scopes = auth.scope.split(' ');
        this.isEditorProperty = this.scopes.includes(editorScope);
        // this.isViewerProperty = this.scopes.includes(viewerScope);

      }

      this.userName = auth.user.name;
      this.customerLogo = auth.customer.logo;

    });
    // }

  }

  /**
   * is user have edit right
   */
  get isEditor() {
    return this.isEditorProperty;
  }

  /**
   * is user have view rights
   */
  get isViewer() {
    return this.isViewerProperty;
  }
}
