import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmationService, MessageService } from 'primeng/api';

import { TopicService } from 'src/app/services/topic.service';
import { ITopic, TopicStatuses } from 'src/app/interfaces/topic.interface';

import { IPlan } from 'src/app/interfaces/plan.interface';
import { PlanService } from '../../../services/plan.service';

import { OrderService } from 'src/app/services/order.service';
import { IOrder } from 'src/app/interfaces/order.interface';
import { MenuService } from 'src/app/services/menu.service';
import { UserService } from 'src/app/services/user.service';

/**
 * View plan component
 */
@Component({
  selector: 'app-plan-view',
  templateUrl: './plan-view.component.html',
  styleUrls: ['./plan-view.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class PlanViewComponent implements OnInit {
  /**
   * Current plan
   */
  planData: IPlan;

  /**
   * list of Topics
   */
  topics: ITopic[] = [];

  /**
   * List of order for selected topic
   */
  orders: IOrder[] = [];

  /**
   * Plan Statuses
   */
  topicStatuses = TopicStatuses;

  /**
   * System busy - plan
   */
  busyPlan = true;

  /**
   * System busy - order
   */
  busyOrder = false;

  /**
   * Init component
   *
   * @param rest plan service
   * @param restTopic topic service
   * @param route active route
   * @param router router
   */
  constructor(
    public rest: PlanService,
    public restTopic: TopicService,
    public restOrder: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public User: UserService,
    public menu: MenuService
  ) {
    this.menu.activeItem = null;
  }

  /**
   * init data
   */
  ngOnInit() {
    this.menu.activeItem = this.menu.items[2];
    this.getPlan();
    this.getTopicsByPlan();
    this.getAllOrders();
  }

  /**
   * Get current plan
   */
  getPlan() {
    this.rest.getPlan(this.route.snapshot.params.id).subscribe(data => {
      this.planData = data;
    });
  }

  /**
   * Get topics for current plan
   */
  getTopicsByPlan() {
    this.rest.getTopicsByPlan(this.route.snapshot.params.id).subscribe(data => {
      this.topics = data;
      this.busyPlan = false;
    });
  }

  /**
   * Get all orders for plan
   */
  getAllOrders() {
    this.busyOrder = true;
    this.restOrder.getOrdersByPlanID(this.route.snapshot.params.id).subscribe(data => {
      this.orders = data;
      this.busyOrder = false;
    });
  }

  /**
   * Open accordion
   *
   * @param event Event
   */
  openAccordion(event) {
    this.busyOrder = true;
    this.restTopic.getOrdersByTopicId(this.topics[event.index].id).subscribe(data => {
      this.orders = data;
      this.busyOrder = false;
    });
  }

  /**
   * Close accordion event handler
   *
   * @param event event
   */
  closeAccordion(event) {
    this.getAllOrders();
  }

  /**
   * Go to order
   *
   * @param order order
   */
  view(order: IOrder) {
    this.router.navigate([`/order-view/${order.id}`]);
  }

  /**
   * remove order
   *
   * @param order Order object
   */
  delete(order: IOrder) {
    if (!this.User.isEditor) {
      return;
    }

    this.confirmationService.confirm({
      // message: 'Are you sure that you want to perform this action?',
      accept: () => {
        this.busyOrder = true;

        this.restOrder.deleteOrder(order).subscribe(
          result => {
            if (result.errors) {
              /*this.messageService.addAll(
                result.errors.map((i) => ({
                  severity: 'warn',
                  summary: 'Delete order',
                  detail: i
                })));*/
            } else {
              const index = this.orders.findIndex(el => order.id === el.id);
              this.orders.splice(index, 1);
              /*this.messageService.add({
                severity: 'success',
                summary: 'Order deleted',
                detail: 'Order successfully deleted'
              });*/
              this.busyOrder = false;
            }
          },
          err => {
            /*this.messageService.addAll(
              [{
                severity: 'warn',
                summary: 'Delete order',
                detail: err
              }]);*/
          }
        );
      }
    });
  }

  /**
   * Get topics status by id
   */
  getTopicStatus(statusId: number) {
    if (this.topicStatuses.find(t => t.value === statusId)) {
      return this.topicStatuses.find(t => t.value === statusId).label;
    } else {
      return 'Error';
    }
  }
}
