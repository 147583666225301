import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { PlanStatuses } from '../../../interfaces/plan.interface';

import CalendarLocaleHe from '../../../../locale/calendar.locale.he';

/**
 * Offer edit component
 */
@Component({
  selector: 'app-plan-edit',
  templateUrl: './plan-edit.component.html',
  styleUrls: ['./plan-edit.component.css']
})
export class PlanEditComponent implements OnInit {
  /**
   * Plan statuses
   */
  planStatuses = PlanStatuses;

  /**
   * locale for Calendar
   */
  he: any = CalendarLocaleHe;

  /**
   * Offer form data
   */
  @Input() planData = {
    id: 0,
    title: '',
    description: '',
    comment: '',
    status: 2,
    date: null,
    file: null
  };

  /**
   * Form validation status
   */
  validation = false;

  /**
   * Init component
   *
   * @param rest order service
   * @param route route
   * @param router router
   */
  constructor(private route: ActivatedRoute, private router: Router, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  /**
   * Init component
   */
  ngOnInit() {
    this.planData = Object.assign({}, this.config.data.plan, {
      date: null, // this.config.data.plan.date ? new Date(this.config.data.plan.date) : null,
      comment: ''
    });
    this.planData.file = null;
  }

  /**
   * File uploader
   *
   * @param event event
   */
  fileUploader(event: any) {
    this.planData.file = event.files[0];
  }

  /**
   * Check description for validation
   */
  changeDescription() {
    if (this.planData.description) {
      this.validation = true;
    } else {
      this.validation = false;
    }
  }

  /**
   * Update order, check and return  offer object
   */
  updatePlan() {
    if (this.planData.title && this.planData.description) {
      if (this.planData.date) {
        this.planData.date.setMinutes(this.planData.date.getMinutes() - this.planData.date.getTimezoneOffset());
      }
      this.ref.close(this.planData);
    }
  }
}
