import { IParticipant } from './participant.interface';
import { ITopic } from './topic.interface';

/**
 * Meeting
 */
export interface IMeeting {
  id: number;
  date: Date;
  protocols: number[]; // protocols id of meeting
  participants: number[]; // current participants id
  topics: number[]; // current topics id
}

/**
 * Meeting list query
 */
export interface IMeetingListOptions {
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  filterDate: string;
  filterProtocols: string;
  filterOrders: string;
  sortField: string;
  sortOrder: number;
}

/**
 * default query
 */
export const defaultMeetingListOptions: IMeetingListOptions = {
  totalRecords: 0,
  pageNumber: 1,
  pageSize: 20,
  filterDate: '',
  filterProtocols: '',
  filterOrders: '',
  sortField: 'date',
  sortOrder: -1
};
