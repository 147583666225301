import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IMeeting } from '../../../interfaces/meeting.interface';
import { MeetingService } from '../../../services/meeting.service';

import { IParticipant, IParticipantListOptions, defaultParticipantListOptions } from '../../../interfaces/participant.interface';
import { ParticipantService } from '../../../services/participant.service';

import { Positions } from '../../../interfaces/position.interface';

import { I18nService } from '../../../services/i18n.service';

import { UserService } from '../../../services/user.service';

import { MenuService } from '../../../services/menu.service';

@Component({
  selector: 'app-meeting-view-participants',
  templateUrl: './meeting-view-participants.component.html',
  styleUrls: ['./meeting-view-participants.component.css']
})
export class MeetingViewParticipantsComponent implements OnInit {
  /**
   * Current meeting
   */
  meetingData: IMeeting;

  /**
   * List of participants
   */
  participants: IParticipant[] = [];

  /**
   * Meeting list of participants
   */
  meetingParticipants: IParticipant[] = [];

  /**
   * List of participants for checkboxes
   */
  checkboxes: string[] = [];

  /**
   * Positions dropdown for filter
   */
  positionsDropdown = [];

  /**
   * Edit mode
   */
  editMode = false;

  /**
   * Loading mode
   */
  loading = false;

  /**
   * System is busy
   */
  busy = true;

  /**
   * Options for request participants list
   */
  participantListOptions: IParticipantListOptions = defaultParticipantListOptions;

  /**
   * Init Component
   * @param rest Meeting service
   * @param restParticipant Participants service
   * @param route active route
   * @param router router
   */
  constructor(
    public rest: MeetingService,
    public restParticipant: ParticipantService,
    private route: ActivatedRoute,
    private router: Router,
    private i18nService: I18nService,
    public User: UserService,
    public menu: MenuService
  ) {
    this.menu.activeItem = null;
  }

  /**
   * Init Data
   */
  ngOnInit() {
    this.menu.activeItem = this.menu.items[0];

    this.positionsDropdown = [
      {
        label: this.i18nService.get('any'), // 'Any',
        value: 0
      },
      ...Positions
    ];

    /* load current meeting */
    this.rest.getMeeting(this.route.snapshot.params.id).subscribe((data: IMeeting) => {
      this.meetingData = data;

      this.getParticipants();
    });
  }

  /**
   * load data
   */
  loadData(event: any) {
    this.participantListOptions = {
      ...this.participantListOptions,
      filterFullName: event.filters && event.filters.fullName ? event.filters.fullName.value : '',
      filterPositionId: event.filters && event.filters.positionId ? event.filters.positionId.value : '',
      filterPhone: event.filters && event.filters.phone ? event.filters.phone.value : '',
      filterEmail: event.filters && event.filters.email ? event.filters.email.value : '',
      sortField: event.field ? event.field : 'isDisciplinary',
      sortOrder: event.order ? event.order : -1
    };
    this.getParticipantsbyFilter();
  }

  /**
   * Navigation, back to meeting list
   */
  back() {
    this.router.navigate(['/meetings']);
  }

  /**
   * Navigation, go to participants list of current meeting
   */
  toMeetingParticipants() {
    this.router.navigate([`/meeting-view/${this.meetingData.id}/participants`]);
  }

  /**
   * Navigation, go to topics list of current meeting
   */
  toMeetingTopics() {
    this.router.navigate([`/meeting-view/${this.meetingData.id}/topics`]);
  }

  /**
   * Navigation, go to Protocols list of current meeting
   */
  toMeetingProtocols() {
    this.router.navigate([`/meeting-view/${this.meetingData.id}/protocols`]);
  }

  /**
   * load all participants
   */
  getParticipants() {
    this.restParticipant.getParticipants().subscribe(data => {
      this.participants = data.data;
      this.checkboxes = this.meetingData.participants.map(p => p.toString());

      this.participants.sort((a, b) => this.checkboxes.indexOf(b.id.toString()));

      this.busy = false;
      this.meetingParticipants = this.participants;
    });
  }

  /**
   * load all participants for sort and filter
   */
  getParticipantsbyFilter() {
    this.restParticipant.getParticipants(this.participantListOptions).subscribe(data => {
      this.participants = data.data;
    });
  }

  /**
   * Get position title by Id
   *
   * @param positionId Position ID
   */
  getPositionById(positionId: number): string {
    // return this.positions[positionId];
    if (this.positionsDropdown.find(p => p.value === positionId)) {
      return this.positionsDropdown.find(p => p.value === positionId).label;
    } else {
      return 'Error';
    }
  }

  /**
   * update meeting with participants and topics
   */
  updateMeeting() {
    if (!this.User.isEditor) {
      return;
    }

    this.busy = true;

    this.meetingData.participants = this.checkboxes.map(p => parseInt(p, 10));

    this.rest.updateMeeting(this.meetingData).subscribe(
      result => {
        this.busy = false;
        // console.log(result);
      },
      err => {
        // console.log(err);
      }
    );
  }
}
