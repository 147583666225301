import { Component, OnInit, Input } from '@angular/core';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';

import { ITopic, ITopicListOptions, defaultTopicListOptions } from '../../../interfaces/topic.interface';
import { TopicService } from '../../../services/topic.service';

/**
 * Add topic form
 */
@Component({
  selector: 'app-topic-clone',
  templateUrl: './topic-clone.component.html',
  styleUrls: ['./topic-clone.component.css']
})
export class TopicCloneComponent implements OnInit {

  /**
   * Input form for topic
   */
  @Input() topicData = {
    id: 0, // offer id
    number: '' // offer title
  };

  /**
   * List of topics
   */
  topics: ITopic[] = [];

  /**
   * Current topic
   */
  topic: ITopic;

  /**
   * Options for load offer list
   */
  topicListOptions: ITopicListOptions = defaultTopicListOptions;

  /**
   * Constructor
   *
   * @param rest Topic service
   * @param restOffer Offerss service
   * @param ref Reference to Dialog
   * @param config Dialog config
   */
  constructor(
    public rest: TopicService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) { }

  /**
   * Init component
   */
  ngOnInit() {
    this.topicListOptions = {
      ...this.topicListOptions,
      pageNumber: 1,
      pageSize: 10,
      filterStatus: '4'
    };
  }

  /**
   * Load topics
   *
   * @param event Event data from autocomplite
   */
  getTopics(event: any) {
    this.topicListOptions.filterNumber = event.query;

    this.rest.getTopics(this.topicListOptions).subscribe((data: any) => {
      this.topics = data.data.filter((s: any) => (s.number.toLocaleLowerCase().indexOf(event.query.toLocaleLowerCase()) === 0));
    });
  }

  /**
   * Add topic
   */
  addTopic() {
    this.topic.meetingId = this.config.data.meetingId;
    this.ref.close(this.topic);
  }
}
